class PieChart extends BaseChart

    constructor: (options={}) ->
        defaults =
            data: {}
            width: undefined # TODO: nejaka automatika podle dodaneho elementu?
            height: undefined
        @options = _.extend defaults, options
        @data = @preprocess_data(@options.data)

        # nejprve musime zanalyzovat co vlastne budeme kreslit
        # povaha dat ma vliv na podobu grafu
        @analysis = @analyze_data()

        # spocteme si rozmery grafu, okraju, apod.
        # (ted uz tusime s cim budeme pracovat)
        @sizes = @get_sizes()

    set_layers: ->
        # hlavni kontejner s SVG elementem
        out =
            root:
                d3.select(@options.container).append("svg")
                    .attr("class", @SVG_CLASS)
                    .attr("width", "100%")
                    .attr("height", "100%")
                    .attr("viewBox", "0 0 #{@options.width} #{@options.height}")

        # vyplivneme jednotlive vrstvy
        for layer in @LAYERS
            out[layer.id] = out.root.append("g").attr('class', "#{layer.id}-layer")
            if layer.transform
                out[layer.id].attr("transform", "translate(#{@sizes.margins.left}, #{@sizes.margins.top})")

        out

    get_sizes: ->
        out =
            width: @options.width
            height: @options.height
            margins: @get_margins()         # marginy uvnitr, oblast mezi samotnou plochou grafu a vnejsimi hranicemi
        out.chart = @get_chart_size(out)    # plocha grafu
        out

    get_margins: ->
        out =
            left: @options.width / 2
            top: @options.height / 2
            right: 0
            bottom: 0

    preprocess_data: (data) ->
        data

    analyze_data: ->
        true

    draw_shapes: () ->
        thickness = @options.width / 20
        radius = Math.min(@options.width, @options.height) / 2

        pie = d3.layout.pie()
            .value((d)-> d)
            .sort(null)

        sectionArc = d3.svg.arc()
            .innerRadius(0)
            .outerRadius(radius)
            .startAngle(0)
            .endAngle (d) ->
                return (2 * Math.PI) * (d / 100)

        backgroundArc = d3.svg.arc()
            .innerRadius(0)
            .outerRadius(radius)

        @SVG.chart.selectAll(".background")
            .data(pie([100]))
            .enter()
            .append("path")
            .attr("class", "color-0-1 dim")
            .attr("d", backgroundArc)

        if not _.isUndefined @data[0]
            sections = @SVG.chart.selectAll(".section").data(@data)
            sections
                .enter()
                .append("path")
                .attr("class", "color-0-1")
                .attr("d", sectionArc)
            sections
                .exit()
                .remove()

    draw: () =>

        # vykresleni grafu
        if _.isUndefined(@SVG)
            # ve strance jeste nic, poskladame cely aparat
            @SVG = @set_layers()
        @draw_shapes()