class AlwaysonTrendChart extends BaseChart

    constructor: (options={}) ->
        defaults =
            data: {}
            width: undefined
            height: undefined
            range: {}
            type: undefined
            justification: 5
            colors: {}
            group: undefined
            format: undefined
            first_wday: 1
        @options = _.extend defaults, options
        @options.comparison = @options.range.length > 1
        @tz = @options.timezone
        @unit_yearly_price = @options.unit_yearly_price
        @unit_daily_sum = @options.unit_daily_sum
        @unit_value = @options.unit_value
        @unique_prefix = parseInt(Math.random() * 10000000)
        @data = @options.data
        @scales = undefined
        @axis = undefined
        @formater = new AlwaysonChartFormater(@unit_value)
        @analysis = @analyze_data()
        @sizes = @get_sizes()

    draw: (show=undefined) ->
        # vykresleni grafu
        if _.isUndefined(@SVG)
            # ve strance jeste nic, poskladame cely aparat
            @SVG = @set_layers()
            @draw_passive_parts()
            @scales = @get_scales()
            @axis = @get_axis()
            @setup_event_layer()
            zero = @sizes.chart.height
            @axis.y.svg_left.transition(CHART_SETTINGS.transition).call(@axis.y.axis_left)
            @axis.y.svg_right.transition(CHART_SETTINGS.transition).call(@axis.y.axis_right)
            @axis.x.svg[0].transition(CHART_SETTINGS.transition).attr("transform", "translate(0, #{zero})").call(@axis.x.axis[0])
        else
            # TODO: dodelat vybuzeni existujiciho grafu

        @draw_background()
        @draw_shapes()

    draw_shapes: () ->

        scale_x_func = @scales.x[@options.period]

        values_array = _.map @data[@options.period], (array)=>
            if not array? or not _.isArray(array)
                return
            if not array[1]? or _.isEmpty array[1]
                return [array[0], null]

            return [array[0], array[1].value]

        line = d3.svg.line()
            .interpolate('linear')
            .x (x)=>
                scale_x_func(x[0])
            .y (y)=>
                @scales.y(y[1])


        trend = @data[@options.period].trend
        if not _.isUndefined(trend) and not _.isNaN(trend.slope)
            # Trendova krivka
            xTimestamps = _.map(_.filter(values_array, (d) -> d[1]?), (d) -> d[0])

            trendline_x_scale = d3.scale.linear()
                     .domain([@analysis.x_min[@options.period], @analysis.x_max[@options.period]])
                     .range([14, (@sizes.chart.width - 14)])

            x1 = trendline_x_scale(_.first(xTimestamps))
            x2 = trendline_x_scale(_.last(xTimestamps))

            _y1 = (parseFloat(trend.slope) * 0) + parseFloat(trend.intercept)
            _y2 = (parseFloat(trend.slope) * (xTimestamps.length - 1))  + parseFloat(trend.intercept)

            y1 = @scales.y(_y1)
            y2 = @scales.y(_y2)

            trendline = @SVG.chart.selectAll(".trendline").data([[x1,y1,x2,y2]])
            trendline.enter()
                .append("line")
                .attr("class", "trendline")
                .attr("x1", (d) -> d[0])
                .attr("y1", (d) -> d[1])
                .attr("x2", (d) -> d[2])
                .attr("y2", (d) -> d[3])
                .attr("stroke", (d) ->
                    if trend.slope <= 0 then "green" else "red"
                )
                .attr("stroke-width", 2)

        # Spojnice hodnot
        shapes = @SVG.chart.selectAll("path").data([values_array])
        shapes.enter()
            .append('path')
            .attr("class", 'line color-0-1 dim group-actual')
            .attr 'd', (d) => line(d)

        fn_area = d3.svg.area()
            .x (x)=>
                scale_x_func(x[0])
            .y0 (y)=>
                @sizes.chart.height
            .y1 (d)=>
                @scales.y(d[1])

        area = @SVG.chart.selectAll("area").data([values_array])
        area.enter()
            .append('path')
            .attr("class", 'area')
            .attr('d', (d) => fn_area(d))

        # Hodnoty pro jednotlive dny/tydny/mesice
        values = @SVG.chart.selectAll("point").data(_.filter(values_array, (d) -> d[1]?))
        values.enter()
            .append("circle")
            .attr("class", 'color-0-1 group-actual')
            .attr("cx", (d)=> scale_x_func(d[0]))
            .attr("cy", (d)=> @scales.y(d[1]))
            .attr("r", (d)-> 2)

    get_scales: () ->
        out =
            x: @get_x_scales()
            y: @get_y_scale()

    get_x_scales: ->
        out = {}
        for group of @data
            out[group] = d3.scale.linear()
                 .domain([@analysis.x_min[group], @analysis.x_max[group]])
                 .range([14, (@sizes.chart.width - 14)])
        out

    get_y_scale: () ->
        d3.scale.linear()
            .domain([0, @analysis.y_max.all + (@analysis.y_max.all/100 * 30) ])
            .range([@sizes.chart.height, 0])

    _analyze_x_data: ->
        out = {x_min: {}, x_max: {}}
        for mode of @data
            out.x_min[mode] = _.first(@data[mode])[0]
            out.x_max[mode] = _.last(@data[mode])[0]
        out

    _analyze_y_data: ->
        out = {y_min: {}, y_max: {}, y_unit: []}

        for mode, values of @data
            # Find minimum and maximum value for every period
            out.y_min[mode] = _.min(_.map(values, (array)-> array[1].value))
            out.y_max[mode] = _.max(_.map(values, (array)-> array[1].value))

        # Also find the minimum and maximum of all periods
        out.y_min['all'] = _.min(_.map(out.y_min, (value)-> value))
        out.y_max['all'] = _.max(_.map(out.y_max, (value)-> value))

        out

    draw_background: ->
        true

    get_x_axises: ->
        out = {axis: [], svg: []}

        period = @options.period
        timestamps = _.map @data[period], (d)-> d[0]

        axis = d3.svg.axis()
            .scale(@scales.x[period])
            .tickValues(timestamps)
            .tickFormat (d) =>
                m = new moment.unix(d).tz(@tz).format(@options.format.day_label)
            .tickPadding(5)
            .orient("bottom")
        out.axis.push(axis)

        out.svg.push(@SVG.axis.append("g")
            .attr("transform", "translate(0, #{@sizes.chart.height})")
            .attr("class", "x axis group-#{period}")
            .call(axis))

        # pridani CSS class na popisky
        @SVG.axis.selectAll("g.x.axis.group-#{period} text")
            .attr 'class', (d) ->
                content = d3.select(@).text()
                if content.indexOf(':') == -1
                    'day'
                else
                    'hour'
        out

    get_bubble_data: () ->
        timestamps = {}
        format = @options.format
        bubble_data =
            width: 1
            period: @options.period
            scale: @scales.x[@options.period]
            empty: =>
                _temp = @merge_timestamps()
                _temp.length < 1
            aligned: (timestamp) =>
                _timestamps = @merge_timestamps()
                _halfs = @merge_timestamps(true)
                _timestamps_len = _.size(_timestamps)

                idx = _.sortedIndex(_halfs, timestamp)
                if idx < 1
                    idx = 0
                else if idx > _timestamps_len
                    idx = _timestamps_len - 1
                _timestamps[idx]
            label: [(d) ->
                    #* Time format for x-axis of "Permanent consumption" graph. You can ignore this
                    d.format(format.day_label)
                ]
            bubble: (timestamp) =>
                out = []
                timepoint = _.find(@data[@options.period], (d) => d[0] == timestamp)

                value_options =
                    switcher: 'consumption'
                    si_prefix: true
                    unit: @unit_value

                sum_options =
                    switcher: 'consumption'
                    si_prefix: true
                    unit: @unit_daily_sum

                cost_options =
                    switcher: 'cost'
                    si_prefix: true
                    unit: @unit_yearly_price

                percent_options =
                    si_prefix: false
                    unit: "%"

                if timepoint?
                    out.push
                        label: format.alwayson_label
                        value: timepoint[1].value
                        class: "color-neutral"
                        type: 'value'
                        unit: {fn: format_si, options: value_options}

                    out.push
                        label: format.period_label
                        value: timepoint[1].daily_sum
                        class: "color-neutral"
                        type: 'value'
                        unit: {fn: format_si, options: sum_options}

                    out.push
                        label: format.share_label
                        value: timepoint[1].daily_percentage
                        class: "color-neutral"
                        type: 'value'
                        unit: {fn: format_si, options: percent_options}

                    out.push
                        label: format.cost_label
                        value: timepoint[1].yearly_price
                        class: "color-neutral"
                        type: 'value'
                        unit: {fn: format_si, options: cost_options}

                else
                    console.warn "timepoint does not exist"

                out
        return bubble_data

    merge_timestamps: (halftime=undefined) ->
        timestamps = _.map(@data[@options.period], (d)=> d[0])
        if halftime?
            halftime_offset = Math.floor((timestamps[1] - timestamps[0]) / 2)
            timestamps = _.map(timestamps, (d)-> d + halftime_offset)
            timestamps.pop(_.last(timestamps))
        return _.sortBy(timestamps, (d) -> d)

    setup_event_layer: ->
        bubble = new AlwaysonBubble
            tz: @tz
            square: @INTERNALS.square
            data: @data
            SVG: @SVG
            sizes: @sizes
            range: @options.range
            unique_prefix: @unique_prefix
            scales: @scales
            helper: @get_bubble_data()
            formater: @formater
            click_handler: ()=>
                # Find out x coordinate of mouse in pixels
                mouse_x = d3.mouse(d3.event.target)[0]
                # Convert the pixel coordinate to timestamp
                timestamp = @scales.x[@options.period].invert(mouse_x)
                # Align the timestamp to one of those in chart
                helper = @get_bubble_data()
                aligned_timestamp = helper.aligned(timestamp)
                # Convert aligned timestamp to "YYYY-MM-DD" string representing start and end of period
                start = moment.unix(aligned_timestamp).tz(@tz).startOf(@options.period).format("YYYY-MM-DD") # TODO: format
                end = moment.unix(aligned_timestamp).tz(@tz).endOf(@options.period).format("YYYY-MM-DD") # TODO: format
                # Let the app know it should handle redirect to detail page
                SavingCenterApp.vent.trigger("chart:click:detail", {start: start, end: end})


    # TODO: upravit rozmery BaseChart a tohle zahodit
    set_layers: ->
        # hlavni kontejner s SVG elementem
        out =
            root:
                d3.select(@options.container).append("div")
                    .attr("class", "svg-wrap")
                    .attr("style", "padding-bottom: #{(@INTERNALS.height / @INTERNALS.width) * 100}%;")
                    .append("svg")
                    .attr("class", @SVG_CLASS)
                    .attr("width", "100%") # NOTE: vnejsi rozmer
                    .attr("height", "100%")
                    .attr("viewBox", "0 0 #{@INTERNALS.width} #{@INTERNALS.height}") # TODO: zajisti plynulou zmenu meritka pri zmene width/height

        # TODO:
        if @DEBUG
            out.root.append('rect').attr('fill', '#fff').style('opacity', .15).attr('width', @sizes.width).attr('height', @sizes.height)

        # maska na chart
        @CLIPPING_ID = "chart-clipping-#{@unique_prefix}"
        out.defs = out.root.append('defs')
        out.defs.append('clipPath')
            .attr('id', @CLIPPING_ID)
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', @sizes.chart.width)
            .attr('height', @sizes.chart.height)

        # vyplivneme jednotlive vrstvy
        for layer in @LAYERS
            out[layer.id] = out.root.append("g").attr('class', "#{layer.id}-layer")
            if layer.transform
                out[layer.id].attr("transform", "translate(#{@sizes.margins.left}, #{@sizes.margins.top})")
            if layer.clipping
                out[layer.id].attr('clip-path', "url(##{@CLIPPING_ID})")

        # TODO
        if @DEBUG
            out.chart.append('rect').attr('fill', '#fff').style('opacity', .15).attr('width', @sizes.chart.width).attr('height', @sizes.chart.height)

        out


class AlwaysonBubble extends Bubble

    update_sizes: ->
        update =
            # popisek do X osy zobrazeny pod kurzorem
            label:
                width: 180
                height: @sizes.margins.bottom - 1
                y: @sizes.height - @sizes.margins.bottom + 2
            # rozmery bublinky do grafu, ve ktere se zobrazuji aktualni hodnoty z kurzoru
            bubble:
                width: 470 # TODO:
                height: 1
                square: @sizes.square * 1.2
                margin: 10

        # vyska bublinky se pocita dynamicky podle poctu streamu v datech
        data_size = _.size(@data)
        if data_size > 1
            # vice skupin v datech == srovnavaci graf
            # (a z kazde se zobrazi jen jeden prubeh)
            height = data_size
        else
            # jedina skupina == detailni graf
            # (udelame misto pro tolik streamu, kolik jich prislo v datech)
            height = _.size(_.values(@data)[0])
        update.bubble.height = (6 + height) * update.bubble.square

        # Y pozice bublinky odvozena z vysky grafu a vysky bubliny
        update.bubble.y = (@sizes.chart.height - update.bubble.height) / 2 + @sizes.margins.top

        @sizes = _.extend(@sizes, update)

class AlwaysonChartFormater extends ChartFormater

    # custom modifications of formatter go here

    constructor: (conf)->
        @unit = conf

    format: (value, format_object=undefined) ->
        if format_object?
            if format_object.fn? and _.isFunction(format_object.fn)
                return format_object.fn(value, format_object.options)
            else if _.isString(format_object)
                return format_si(value, format_object)
            else if _.isArray(format_object) and _.isEmpty(format_object)
                return format_si(value, {unit: @unit, strip_zeros: true})
            else
                return value
