class HeatmapChart extends BaseChart

    # Velika naivita, ale vzhledem k tomu,
    # ze uzivatele zatim nemuzou zadat jine rozpeti nez my urcime,
    # tak se na tohle da pomerne dobre spolehnout
    _hasDSTswitch: (start, end, timezone)->
        is_start_in_DST = moment.unix(start).tz(timezone).isDST()
        is_end_in_DST = moment.unix(end).tz(timezone).isDST()
        return {
            occured: not is_start_in_DST == is_end_in_DST # pokud neni zacatek a konec v stejnem case (letnim/zimnim) vrati true
            summer_to_winter: is_start_in_DST and not is_end_in_DST
        }

    _findDSTswitch: (data_array, summer_to_winter=undefined)->
        if not summer_to_winter?
            return undefined

        if summer_to_winter
            # Rozdelim periodu na [[DST timestamps],[non-DST timestamps]]
            parts = _.partition(data_array, (d) =>
                moment.unix(d[0]).tz(@tz).isDST()
            )
            mark = _.first(parts[1][0])
            # Vratim info o tom kdy se prepina a posledni 4 DST 15-ti min. timestampy
            return {
                time_of_switch: mark
                omit: [mark-3600, mark-2700, mark-1800, mark-900]
            }
        else
            # TODO:
            return undefined

    preprocess_data: (data)->

        # vytvorim si objekt, kde kazdy klic je utc timestamp zarovnany na 15 minut od start po end
        _DSTskeleton = []
        for i in [@options.range.actual.start..@options.range.actual.end] by 900
            _DSTskeleton.push [i,undefined]
        # zjistim, jestli v nem doslo ke zmene casu
        time_change = @_hasDSTswitch(@options.range.actual.start, @options.range.actual.end, @tz)

        # pokud ano a je to zmena z letniho na zimni
        if time_change.occured and time_change.summer_to_winter
            # najdu prvni timestamp zimniho casu
            time_change_info = @_findDSTswitch(_DSTskeleton, time_change.summer_to_winter)
            # za skeletonu zahodim ctyri timestampy pred nim

            # _DSTskeleton = _.reject _DSTskeleton, (d)=>
                # if ((d[0] < time_change_info.time_of_switch) and (d[0] >= time_change_info.omit[0]))
                    # console.warn "removing #{d[0]} -> " + moment.unix(d[0]).tz(@tz).format()
                # return ((d[0] < time_change_info.time_of_switch) and (d[0] >= time_change_info.omit[0]))

        skeleton = []
        for i in [@options.range.actual.start..@options.range.actual.end] by 900
            if time_change.occured and time_change.summer_to_winter
                if _.indexOf(time_change_info.omit,i) == -1
                    skeleton.push [i,undefined]
            else
                skeleton.push [i,undefined]

        skeleton = _.object skeleton

        # pro vsechny streamy
        for stream, stream_value of data.actual.data
            # napasuju na sebe timestampy ze skeleton a z dat
            _data = _.object(_.map(stream_value.data, (d) ->
                [d[0], parseFloat(d[1])]
            ))

            if time_change.occured and time_change.summer_to_winter
                for i in time_change_info.omit
                    delete _data[i]

            merged = _.extend(_.clone(skeleton),_data)
            _data = null

            # zgrupuju je po hodinach
            groupsByHour = _.groupBy merged, (measurements,timestamp) ->
                t = parseInt(timestamp)
                t - t % 3600
            merged = null

            # spocitam sumy pres hodiny a poznacim si, jestli nekde chybely data
            integrityCheck = _.map(
                groupsByHour, (measurements, timestamp) ->
                    measurements_in_hour = _.compact(measurements).length

                    'timestamp': timestamp
                    'sum':_.reduce(measurements,(memo,measurement) ->
                        if not measurement? or _.isNaN measurement
                            return memo
                        else
                            return memo + measurement
                    ,null) # vychozi hodnota memo
                    'avg': if measurements_in_hour == 0 then null else _.reduce(measurements,(memo,measurement) ->
                        if not measurement?
                            return memo
                        else
                            return memo + measurement
                    ,null) / measurements_in_hour
                    'corrupt':_.reduce(measurements,(temp,value) ->
                        if not value? or temp
                            return true # corrupt: true
                        else
                            return false # corrupt: false
                    ,false) # vychozi hodnota temp
            )
            groupsByHour = null

            # podrobne data uz nejsou potreba
            stream_value.data = {}

            # pretavim to do konecne podoby dat
            stream_value.data.values = _.flatten(_.map(
                integrityCheck, (value, key) =>
                    [
                        'day':((key - key % 24) / 24)
                        'hour':(key % 24)
                        'corrupt':value.corrupt
                        'value': if @options.medium == 'temperature' then value.avg else value.sum
                        'timestamp': value.timestamp
                    ]
            ))
            integrityCheck = null

            valuesByDay = _.groupBy(stream_value.data.values, (r)-> r.day)

            stream_value.data.totals = _.map(valuesByDay, (val, day_index, whole) =>
                        _tot = _.reduce(val, (memo, v)->
                            if v.value? and not _.isNaN(v.value)
                                return {
                                    sum: memo.sum + v.value
                                    count: memo.count + 1
                                }
                            else
                                return {
                                    sum: memo.sum
                                    count: memo.count
                                }
                        , {sum: null, count: 0})
                        if @options.medium == 'temperature'
                            return _tot.sum / _tot.count
                        else
                            return _tot.sum
                    )

            stream_value.data.dates = []

            for i in [0...@options.range.actual.days]
                stream_value.data.dates.push moment.unix(@options.range.actual.start).tz(@tz).add('days',i).unix()

        return data

    analyze_data: ()->
        true

    # Vrati nejcetnejsi SI prefix hodnot v poli (array)
    #
    get_array_dominant_prefix: (array) ->
        if array?
            values = []
            for item in array
                values.push _numeral_si_prefix(parseInt(item))
            if values.length > 0
                _.last(_.sortBy(_.pairs(_.countBy(values)),(d) -> d[1]))[0]
            else
                false
        else
            false

    # Definuje okraje kolem grafu.
    #
    get_margins: ->
        out =
            left: @INTERNALS.square * 6
            top: @INTERNALS.square * 2
            right: @INTERNALS.square * 2
            bottom: @INTERNALS.square * 2

    # Rozmery plochy, do ktere se kresli prubeh.
    #
    get_chart_size: (sizes) ->
        out =
            width: sizes.width - sizes.margins.left - sizes.margins.right
            height: sizes.height - sizes.margins.top - sizes.margins.bottom

    get_sizes: ->
        # U promenliveho poctu dnu k zobrazeni je potreba nejaka priblizna vyska jednoho radku/dne pro vypocet vysky grafu/odsazeni jeho wrapperu
        # Hodnota je v podstate vycucana z prstu metodou pokus/omyl
        APPROX_DAY_HEIGHT = 35

        out =
            width: @options.width
            height: if @options.for_reports then (@options.height + (@options.range.actual.days * APPROX_DAY_HEIGHT)) else @options.height
            margins: @get_margins()         # marginy uvnitr, oblast mezi samotnou plochou grafu a vnejsimi hranicemi
        out.chart = @get_chart_size(out)    # plocha grafu
        out.cell =
            width: Math.floor out.chart.width / 26
            height: Math.floor out.chart.width / 30
        out

    # Nastavi zakladni vrsty, do kterych se pak kresli samotny graf.
    #
    set_layers: ->

        # hlavni kontejner s SVG elementem
        out =
            root:
                d3.select(@options.container).append("div")
                    .attr("class", "svg-wrap")
                    .attr("style", if @options.for_reports then "padding-bottom: #{(@sizes.height / @sizes.width) * 100}%;" else "padding-bottom: 33%;")
                    .append("svg")
                    .attr("class", @SVG_CLASS)
                    .attr("width", "100%")
                    .attr("height", "100%")
                    .attr("preserveAspectRatio", "xMinYMin meet")
                    .attr("viewBox", "0 0 #{@sizes.width} #{@sizes.height}") # TODO: zajisti plynulou zmenu meritka pri zmene width/height

        # vyplivneme jednotlive vrstvy
        for layer in @LAYERS
            out[layer.id] = out.root.append("g").attr('class', "#{layer.id}-layer")
            if layer.transform
                out[layer.id].attr("transform", "translate(#{@sizes.margins.left}, #{@sizes.margins.top})")

        out

    draw: (show=undefined)->

        if _.isString(show)
            show = [show]
        else if _.isUndefined(show)
            show = _.map @data, (d, i) -> d.root_id
        @show = show

        if _.isUndefined(@SVG)
            @SVG = @set_layers()

        @draw_shapes(@show)



    draw_shapes: (ids) ->

        _data = @reject_level_streams(@filter_root_ids(@data.actual.data, ids))

        contents = _data[0]

        cw = @sizes.cell.width
        ch = @sizes.cell.height

        max_val= if contents? and contents.data? then d3.max(contents.data.values, (d) -> d.value) else 0
        min_val= if contents? and contents.data? then d3.min(contents.data.values, (d) -> d.value) else 0
        max_sum_val = _.max(contents.data.totals)
        min_sum_val = _.min(contents.data.totals)
        dominant_prefix = @get_array_dominant_prefix(contents.data.totals)

        if @options.medium == 'temperature'
            if min_val < 0
                color_scale = d3.scale.linear().domain([min_val, -0.01, 0.01, max_val]).range(@options.colors.temperature.negative)
            else
                color_scale = d3.scale.linear().domain([min_val, max_val]).range(@options.colors.temperature.positive)

        else if @options.medium == 'power'
            color_scale = d3.scale.linear().domain([0, max_val / 2, max_val]).range(@options.colors.power)
        else if @options.medium == 'gas'
            color_scale = d3.scale.linear().domain([0, max_val / 2, max_val]).range(@options.colors.gas)
        else if @options.medium == 'water'
            color_scale = d3.scale.linear().domain([0, max_val / 2, max_val]).range(@options.colors.water)
        else
            color_scale = d3.scale.linear().domain([0, max_val / 2, max_val]).range(@options.colors.power)
        legend_scaling = 1.3
        now_timestamp = App.Clocker.now.unix()

        link_to_detail = (d) =>
            if not(_.isNumber(d))
                return true

            if _.isUndefined(@options.detail_panel)
                return true

            start = moment.unix(d).tz(@tz).startOf('day').format("YYYY-MM-DD")
            pstart = moment.unix(d).tz(@tz).startOf('day').subtract('days',1).format("YYYY-MM-DD")
            end = start
            pend = pstart
            stream = ids[0]
            url = @options.detail_panel
            App.Router.router.navigate("#{url}/cstream=#{stream},dstream=#{stream},end=#{end},feed=#{contents.feed},pend=#{pend},pstart=#{pstart},start=#{start},switcher=#{@options.switcher}", { trigger: true })
            true

        @SVG.chart.selectAll('defs')
            .data(['dummy'])
            .enter()
            .append('pattern')
            .attr('id', "diagonalHatch" )
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
            .attr('stroke', '#000000')
            .attr('stroke-width', 1)
            .attr('stroke-opacity', .5)

        for day_index, day_ts of contents.data.dates
            dayGroup = @SVG.chart.selectAll(".day-#{day_index}").data(["dummy"])

            dayGroup.enter()
                .append("g")
                .attr("class", "day day-#{day_index}")
                .attr("x", 0)
                .attr("y", (d,i) -> day_index * ch)
                .attr("width", cw * 24)
                .attr("height", ch)
                .style("cursor", "pointer")
                .on("click", (d) -> link_to_detail(day_ts))

            dayGroup.exit()
                .on("click",null)
                .remove()

            day_values = _.filter(contents.data.values, (d)-> d.day == parseInt(day_index))

            hour = dayGroup.selectAll(".hour") # bunka pro jednu hodinu
                .data(day_values)
                .attr("class", (d) ->
                    if ((d.timestamp <= now_timestamp) and not _.isNull(d.value)) then "hour bordered" else "hour"
                )
                .style("fill", (d) -> if ((d.timestamp <= now_timestamp) and not _.isNull(d.value)) then color_scale(d.value) else 'none' )

            hour.enter()
                .append("rect")
                .attr("x", (d) -> d.hour * cw)
                .attr("y", (d) -> d.day * ch)
                .attr("rx", 3)
                .attr("ry", 3)
                .attr("class", (d) ->
                    if ((d.timestamp <= now_timestamp) and not _.isNull(d.value)) then "hour bordered" else "hour"
                )
                .attr("width", cw)
                .attr("height", ch)
                .style("fill", (d) -> if ((d.timestamp <= now_timestamp) and not _.isNull(d.value)) then color_scale(d.value) else 'none' )

            hour.exit()
                .remove()

            corrupt = dayGroup.selectAll(".corrupt") # vysrafuje bunku, pokud chybi jedna hodnota v ramci hodiny
                .data(day_values)
                .style("fill", (d) -> if (d.corrupt and ((d.timestamp <= now_timestamp) and not _.isNull(d.value))) then 'url(#diagonalHatch)' else 'none' )
            corrupt.enter()
                .append("rect")
                .attr("x", (d) -> d.hour * cw)
                .attr("y", (d) -> d.day * ch)
                .attr("width", cw)
                .attr("height", ch)
                .attr("class", "corrupt")
                .style("fill", (d) -> if (d.corrupt and ((d.timestamp <= now_timestamp) and not _.isNull(d.value))) then 'url(#diagonalHatch)' else 'none' )
            corrupt.exit()
                .remove()

            check = dayGroup.selectAll(".check") # vypise hodnoty do bunek
                .data(day_values)
                .text((d) -> if (d.timestamp <= now_timestamp) then format_si(d.value,{precision:1}) )
            check.enter()
                .append("text")
                .attr("x", (d) -> d.hour * cw)
                .attr("y", (d) -> d.day * ch)
                .attr("transform", "translate(#{cw / 2},#{ch / 1.5})")
                .attr("class", ()=> if (@options.for_reports or Modernizr.touch) then "check check_visible" else "check")
                .style("text-anchor", "middle")
                .text((d) -> if (d.timestamp <= now_timestamp) then format_si(d.value,{precision:1}) )
            check.exit()
                .remove()

        sum_bg_scaling = 1.7
        sum_bg_gutter = 10

        if min_sum_val < 0
            range = Math.abs(min_sum_val) + max_sum_val
        else
            range = max_sum_val - min_sum_val

        sum_bg_width = cw * sum_bg_scaling
        sum_bg_ratio = sum_bg_width / range

        daySumBg = @SVG.chart.selectAll(".daySumBg") # podbarvi bunku pro denni sumu
            .data(contents.data.totals)
        daySumBg.enter()
            .append("rect")
            .attr("x", (d, i) ->
                if d < 0
                    return d * sum_bg_ratio

                return 0
            )
            .attr("y", (d, i) -> i * ch)
            .attr("width", (d, i) ->
                if sum_bg_ratio == Number.POSITIVE_INFINITY or sum_bg_ratio == Number.NEGATIVE_INFINITY
                    return Math.abs(d)

                Math.abs(d) * sum_bg_ratio
            )
            .attr("height", ch)
            .attr("class", (d, i) =>
                if @options.medium == 'temperature' and d > 0
                    return "bordered daySumBg tempPlus"

                if @options.medium == 'temperature' and d < 0
                    return "bordered daySumBg tempMinus"

                return "bordered daySumBg"
            )
            .attr("transform", ()->
                last_hour_of_day = cw * 24
                if min_sum_val < 0
                    zero_value_offset = Math.abs(min_sum_val) * sum_bg_ratio
                else
                    zero_value_offset = 0

                return "translate(#{last_hour_of_day + sum_bg_gutter + zero_value_offset},0)"
            )
        daySumBg.exit()
            .remove()


        daySum = @SVG.axis.selectAll(".daySum") # vykresli bunku pro denni sumu
            .data(contents.data.totals)
            .text((d)=>
                format_si(d,{si_prefix:dominant_prefix,unit:contents.unit,precision:1})
            )
        daySum.enter()
            .append("text")
            .text((d)=>
                format_si(d,{si_prefix:dominant_prefix,unit:contents.unit,precision:1})
            )
            .attr("x", (cw * 24) + sum_bg_gutter + sum_bg_width )
            .attr("y", (d, i) -> i * ch)
            .style("text-anchor", "end")
            .attr("class", "daySum")
            .attr("transform", "translate(5,#{ch / 1.5})")
        daySum.exit()
            .remove()

        dayLabel = @SVG.axis.selectAll(".dayLabel") # vypise popisek dne
            .data(contents.data.dates)
            .text((d)=>
                moment.unix(d).tz(@tz).format(@options.format)
            )
            .attr("class", (d) => if moment.unix(d).tz(@tz).isoWeekday() in [1..5] then "dayLabel" else "dayLabel weekEnd")
        dayLabel.enter()
            .append("text")
            .text((d)=>
                moment.unix(d).tz(@tz).format(@options.format)
            )
            .attr("x", 0)
            .attr("y", (d, i) -> i * ch)
            .style("text-anchor", "end")
            .attr("transform", "translate(-10,#{ch / 1.5})")
            .attr("class", (d) => if moment.unix(d).tz(@tz).isoWeekday() in [1..5] then "dayLabel" else "dayLabel weekEnd")
        dayLabel.exit()
            .remove()

        times = ["00:00","01:00","02:00","03:00","04:00","05:00","06:00","07:00","08:00","09:00","10:00","11:00","12:00","13:00","14:00","15:00","16:00","17:00","18:00","19:00","20:00","21:00","22:00","23:00","00:00"]

        hourLabel = @SVG.axis.selectAll(".hourLabel") # popisky pro hodiny pod grafem
            .data(times)
            .enter()
            .append("text")
            .text((d)-> d)
            .attr("x", (d, i) -> (i * cw)-5 )
            .attr("y", 0)
            .style("text-anchor", "end")
            .attr("transform", "translate(#{cw / 2},#{ch * (@options.range.actual.days + 0.5)})")
            .attr("class", "hourLabel")

        legendLabel = @SVG.axis.selectAll(".legendLabel") # popisky legendy
            .data(color_scale.ticks())
            .text((d) => format_si(d,{unit:contents.unit,strip_zeros:true}))
        legendLabel.enter()
            .append("text")
            .text((d) => format_si(d,{unit:contents.unit,strip_zeros:true}))
            .attr("x", (d, i) -> i * cw * legend_scaling)
            .attr("y", 0)
            .style("text-anchor", "middle")
            .attr("transform", "translate(#{cw * legend_scaling / 2},#{ch * (@options.range.actual.days + 1.25)})")
            .attr("class", "legendLabel")
        legendLabel.exit()
            .remove()

        legend = @SVG.axis.selectAll(".legend") # bunky legendy
            .data(color_scale.ticks())
            .style("fill", (d) -> color_scale d)
        legend.enter()
            .append("rect")
            .attr("x", (d,i) -> i * cw * legend_scaling)
            .attr("y", (d,i) -> ch)
            .attr("rx", 2)
            .attr("ry", 2)
            .attr("class", "legend bordered")
            .attr("width", cw * legend_scaling)
            .attr("height", ch / 2)
            .attr("transform", "translate(0,#{ch * (@options.range.actual.days + 0.5)})")
            .style("fill", (d) -> color_scale d)
        legend.exit()
            .remove()
