# TODO:
# - vyska bubliny
#   - zavisi na datech
#   - datumu
#   - seznamu naklikanych streamu
#
# - jakym zpusobem kurzor skace
#   - jestli po pravidelnem stepu
#   - nebo po hodnotach v
#
# - odregistraci posluchacu eventu!
class Bubble

    constructor: (options={}) ->

        @options = options
        # TODO:
        # quick & dirty
        @tz = options.tz
        @unique_prefix = options.unique_prefix

        @data = options.data
        @SVG = options.SVG
        @sizes = _.clone(options.sizes)
        @sizes.square = options.square
        @update_sizes()
        @helper = options.helper
        @formater = options.formater

        @svg_definitions(@sizes.label.width, @sizes.label.height)
        @elements = @set_elements()
        @set_handlers()

    # Zaveseni handleru na udalosti mysi.
    #
    set_handlers: ->
        @set_mouseover_handler()
        @set_mouseout_handler()
        @set_mousemove_handler()
        @set_mouseclick_handler(@options.click_handler)

    # Pokud je definovany click handler, zaregistrujem ho
    #
    set_mouseclick_handler: (handler_function)->
        elements = @elements

        if handler_function? and _.isFunction handler_function
            elements.event.on 'click', handler_function

    # Jakmile mys vleze do utrob grafu, rozneme kurzor a bublinu.
    #
    set_mouseover_handler: ->
        cursor_opacity = if @helper.width > 1 then .1 else .5
        elements = @elements
        empty = @helper.empty

        elements.event.on 'mouseover', ->
            if empty()
                return
            elements.cursor.transition(CHART_SETTINGS.transition).style('opacity', cursor_opacity)
            elements.label.transition(CHART_SETTINGS.transition).style('opacity', 1)
            elements.bubble.transition(CHART_SETTINGS.transition).style('opacity', 1)

    # Jakmile mys z grafu vyleze, kurzor i bublinu zase zhasneme.
    #
    set_mouseout_handler: ->
        elements = @elements

        elements.event.on 'mouseout', ->
            elements.cursor.transition(CHART_SETTINGS.transition).style('opacity', 0)
            elements.label.transition(CHART_SETTINGS.transition).style('opacity', 0)
            elements.bubble.transition(CHART_SETTINGS.transition).style('opacity', 0)

    # Tady se deje to dulezite -- kazdy pohyb mysi v grafu muze (ale nemusi)
    # zpusobit presun kurzoru a aktualizaci textovych udaju z jeho nove pozice.
    #
    set_mousemove_handler: ->

        # TODO: toto se mi moc nelibi...
        sizes = @sizes
        data = @data
        helper = @helper
        group = @helper.group
        last_timestamp = undefined
        elements = @elements
        tz = @tz
# TODO: tohle se musi nastavit nejak dynamicky
# - penize se formatuji jinak nez treba W
# -
        formater = @formater
        empty = @helper.empty

        handle_mouseover = ()->
            if empty()
                return
            # prevedeme si pozici mysi na casovy okamzik na ose
            position = d3.mouse(@)
            timestamp = helper.scale.invert(position[0])
            timestamp = helper.aligned(timestamp)
            if _.isUndefined(timestamp)
                return

            if last_timestamp != timestamp
                # kurzor (cara nebo sloupecek na pozadi)
                elements.cursor.attr('x', helper.scale(timestamp))

                # popisek pod kurzorem (prekryva hodnoty v ose X)
                label_x = sizes.margins.left + helper.scale(timestamp) - sizes.label.width / 2 + helper.width / 2
                elements.label.attr("transform", "translate(#{label_x}, #{sizes.label.y})")
                date = moment.unix(timestamp).tz(tz)

                # TODO: format popisku se musim dozvedet z venku
                #elements.label.select('text').text(date.format('DD.MM. HH:mm:ss'))

                t = elements.label.select('text').selectAll('tspan').data(helper.label)
                t.text((fn) -> fn(date))
                t.enter()
                    .append('tspan')
                    .attr('x', sizes.label.width/2)
                    .attr('dy', sizes.square)
                    .text((fn) -> fn(date))

                # bublinka (prekryva prubehy v grafu)
                if helper.scale(timestamp) < sizes.bubble.width
                    bubble_x = sizes.margins.left + helper.scale(timestamp) + helper.width + sizes.bubble.margin
                else
                    bubble_x = sizes.margins.left + helper.scale(timestamp) - sizes.bubble.width - sizes.bubble.margin
                elements.bubble.attr("transform", "translate(#{bubble_x}, #{sizes.bubble.y})")

                obsah = (d) ->
                    if d.value != undefined
                        "#{d.label}: #{formater.format(d.value, d.unit)}"
                    else
                        "#{d.label}: -"

                bubble_content = elements.bubble.selectAll('text').data(helper.bubble(timestamp))
                bubble_content.exit().remove()
                bubble_content
                    .attr('x', sizes.square)
                    .attr('y', (d, i) => (i+1) * sizes.bubble.square + sizes.square)
                    .attr('class', (d) -> d.class)
                    .text((d) -> obsah(d))
                bubble_content.enter()
                    .append('text')
                    .attr('x', sizes.square)
                    .attr('y', (d, i) => (i + 1) * sizes.bubble.square + sizes.square)
                    .attr('class', (d) -> d.class)
                    .text((d) -> obsah(d))

                if _.isFunction(d3.selection.prototype.textwrap)
                    elements.bubble.selectAll('*').textwrap(d3.select(".bubble-bg"))

                last_timestamp = timestamp

        elements.event.on('mousemove', _.throttle(handle_mouseover, 50, {leading: false, trailing: false}))

    # Vygeneruje do SVG elementy, ktere bublinka pouziva: kurzor v grafu, popisek
    # pod kurzorem a cerne okynko nad grafem.
    # Bacha: tady se neresi obsah, je to jen setup.
    #
    set_elements: ->
        # vrstva odchytavajici udalosti; je uplne nejvys a ma na sobe vykreslen
        # jeden velky # pruhledny obdelnik (odchytavani udalosti nad <g> totiz nefunguje)
        event = @SVG.events.append('rect')
                    .attr('width', @sizes.chart.width)
                    .attr('height', @sizes.chart.height)
                    .attr('opacity', 0)

        # kdesi pod prubehy zije cara/obdelnik, ktera kopiruje pohyb mysi
        cursor = @SVG.highlight.append('rect')
                    .attr('y', 0)
                    .attr('width', @helper.width)
                    .attr('height', @sizes.chart.height)
                    .attr('class', 'cursor')

        # hodnoty v ose X jsou prekryty aktualni hodnotou, ktera odpovida pozici
        # kurzoru v grafu
        label = @SVG.overlay.append('g')
                    .attr('class', 'label')
                    .attr('mask', (d) => "url(#mask-#{@unique_prefix}-cursorlabel)")
                    .attr("transform", "translate(0, #{@sizes.label.y})")
        label
            .append('rect')
            .attr('width', @sizes.label.width)
            .attr('height', @sizes.label.height)
        label
            .append('text')
            .attr('x', @sizes.label.width/2)
            .attr('y', 6)
        if @helper.width > 1
            # linka pod sloupeckem
            label
                .append('line')
                .attr('x1', (@sizes.label.width-@helper.width) / 2)
                .attr('y1', 1)
                .attr('x2', (@sizes.label.width-@helper.width) / 2 + @helper.width)
                .attr('y2', 1)
        label.style('opacity', 0)

        # bublinka v grafu
        bubble = @SVG.overlay.append('g')
                    .attr('class', 'bubble')
                    .attr("transform", "translate(0, #{@sizes.bubble.y})")
                    .style('opacity', 0)
        bubble
            .append('rect')
            .attr('class', 'bubble-bg')
            .attr('width', @sizes.bubble.width)
            .attr('height', @sizes.bubble.height)

        out =
            event: event
            cursor: cursor
            label: label
            bubble: bubble

    # SVG struktury do <defs> tagu. S jeho pomoci se obecne definuji patterny,
    # masky, clipping oblasti, apod.
    #
    svg_definitions: (width, height) ->
        # Gradient pro pozadi obdelnicku, do ktereho se na ose X kresli
        # aktualni hodnota.
        gradient = @SVG.defs.append('linearGradient')
            .attr('id', "mask-#{@unique_prefix}-cursorlabel-gradient")
        gradient
            .append('stop')
            .attr('offset', 0)
            .attr('stop-color', "#fff")
            .attr('stop-opacity', 0)
        gradient
            .append('stop')
            .attr('offset', 0.2)
            .attr('stop-color', "#fff")
            .attr('stop-opacity', 1)
        gradient
            .append('stop')
            .attr('offset', 0.8)
            .attr('stop-color', "#fff")
            .attr('stop-opacity', 1)
        gradient
            .append('stop')
            .attr('offset', 1)
            .attr('stop-color', "#fff")
            .attr('stop-opacity', 0)

        # Maska pro obdelnicek. Ta zaridi, ze barva pozadi v obdelniku pujde
        # do ztracena.
        mask = @SVG.defs.append('mask')
            .attr('id', "mask-#{@unique_prefix}-cursorlabel")
        mask.append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', width)
            .attr('height', height)
            .attr('fill', "url(#mask-#{@unique_prefix}-cursorlabel-gradient)")

    # Krome informaci o rozmerech samotneho grafu potrebujem pro ucely bubliny
    # jeste dalsi udaje.
    #
    update_sizes: ->
        update =
            # popisek do X osy zobrazeny pod kurzorem
            label:
                width: 180
                height: @sizes.margins.bottom - 1
                y: @sizes.height - @sizes.margins.bottom + 1
            # rozmery bublinky do grafu, ve ktere se zobrazuji aktualni hodnoty z kurzoru
            bubble:
                width: 200 # TODO:
                height: 1
                square: @sizes.square * 1.2
                margin: 10

        # vyska bublinky se pocita dynamicky podle poctu streamu v datech
        data_size = _.size(@data)
        if data_size > 1
            # vice skupin v datech == srovnavaci graf
            # (a z kazde se zobrazi jen jeden prubeh)
            height = data_size
        else
            # jedina skupina == detailni graf
            # (udelame misto pro tolik streamu, kolik jich prislo v datech)
            height = _.size(_.values(@data)[0])
        update.bubble.height = (4 + height) * update.bubble.square

        # Y pozice bublinky odvozena z vysky grafu a vysky bubliny
        update.bubble.y = (@sizes.chart.height - update.bubble.height) / 2 + @sizes.margins.top

        @sizes = _.extend(@sizes, update)
