# Graf se spojitym prubehem.
# Srovnavaci varianta, kazdy z prubehu vykresli jako krivku a s pomoci CSS
# pravidel je zarizeno, ze aktualni prubeh je prezentovan jako cara, kdezto
# predchozi obdobi jako plocha.
#
class ContinuousComparisonChart extends BaseComparisonChart

    # TODO:
    # - tady je problem v mergi 2 casovych posloupnosti
    #   - lezi casove jinde
    #   - maji jiny rytmus
    #   - nepujde vyzobnout hodnotu na presne pozici
    #
    get_bubble_data: () ->
        timestamps = {}
        group = 'actual'
        format = @options.format
        scale = @get_x_scale_variant(group)
        get_ids = => @show

        # casove znacky z actual grupy
        timestamps_for_aligned = {}
        for key, stream of @data.actual.data
            timestamps_for_aligned[stream.root_id] = _.map(stream.data, (d) -> d[0])

        # datova struktura pro rychlejsi nalezeni streamu
        streams_for_bubble =
            actual: _.object(_.map(@data.actual.data, (v) -> [v.root_id, v]))
            previous: _.object(_.map(@data.previous.data, (v) -> [v.root_id, v]))

        out =
            width: 1
            group: group
            scale: scale
            empty: =>
                ids = get_ids()
                if not ids[0]
                    return true
                if not timestamps_for_aligned[ids[0]]
                    return true
                if timestamps_for_aligned[ids[0]].length < 1
                    return true
                return false
            aligned: (timestamp) =>
                ids = get_ids()
                key = ids[0]

                _timestamps = timestamps_for_aligned[key]
                _timestamps_len = _.size(_timestamps)

                idx = _.sortedIndex(_timestamps, timestamp)
                if idx < 1
                    idx = 1
                else if idx > _timestamps_len
                    idx = _timestamps_len - 1
                _timestamps[idx]

            label: [((d) -> d.format(format.label))]

            bubble: (timestamp) =>
                out = []
                ids = get_ids()

                stream = streams_for_bubble.actual[ids[0]]
                value = _.find(stream.data, (d) -> d[0] == timestamp)
                if value?
                    value = if (_.isObject(value[1]) and _.has(value[1], 'sum_value')) then value[1].sum_value else value[1]
                out.push
                    #* This term is dependent on context value. Please see More details and submit translation for what you see in "Context".
                    label: format.bubble_label
                    value: value
                    unit: stream.unit
                    class: "color-#{stream.color1 or 0}-#{stream.color2 or 0}"
                    type: 'value'

                # hodnota pro predchozi obdobi (nalezeni nejblizsiho vzorku)
                # Bacha! Musi se brat v potaz vypadky, at se jako nejblizsi hodnota nenajde
                # treba neco co bylo platne pred 12 hodinama...
                delta = timestamp - scale.domain()[0]
                pscale = @get_x_scale_variant('previous')
                prev_timestamp = pscale.domain()[0] + delta

                stream = streams_for_bubble.previous[ids[0]]
                value = undefined

                if stream?
                    outage = stream.outages.length > 0 and _.any(stream.outages, (d) -> prev_timestamp >= d[0] and prev_timestamp < d[1])

                    if not outage
                        _timestamps = stream.data
                        _timestamps_len = _.size(_timestamps)-1

                        idx = _.sortedIndex(_timestamps, [prev_timestamp], (d) -> d[0])
                        if idx < 1
                            idx = 1
                            narrow = [_timestamps[idx], _timestamps[idx+1]]
                        else if idx >= _timestamps_len
                            idx = _timestamps_len
                            narrow = [_timestamps[idx-1], _timestamps[idx]]
                        else
                            narrow = [_timestamps[idx-1], _timestamps[idx], _timestamps[idx+1]]

                        uztobude = _.map(narrow, (d) -> [Math.abs(d[0]-prev_timestamp), d[1]])

                        if Math.abs(_timestamps[idx][0]-prev_timestamp) < 180
                            value = _.min(uztobude, (d) -> d[0])[1]

                    if not(is_level_stream(stream.id))
                        out.push
                            label: format.bubble_label
                            value: value
                            unit: stream.unit
                            class: "color-#{stream.color1 or 0}-#{stream.color2 or 0}"
                            type: 'value'
                else
                    if (stream.id.indexOf(":") == -1)
                        out.push
                            label: format.bubble_label
                            value: value
                            unit: null
                            class: "color-0-0"
                            type: 'value'

                out

    draw_background: ->
        main_group = _.keys(@data)[0]
        if @data[main_group].switching != undefined
            @draw_regions(@data[main_group].switching)

    draw_shapes: (ids) ->
        # vyfiltrujeme jen zvoleny stream
        _data =
            actual: @reject_level_streams(@filter_root_ids(@data.actual.data, ids))
            previous: @reject_level_streams(@filter_root_ids(@data.previous.data, ids))

        # vytahnem jmena skupin
        groups = _.keys(_data)

        # bloky
        # NOTE: tady neni treba mit update block, protoze grupy se v case nemeni
        # (meni se jen jejich obsah, takze opravdu jen jednorazove vykresleni)
        blocks = @SVG.chart.selectAll("g.block").data(groups)
        blocks.enter()
            .append('g')
            .attr("class", (d) -> "block continuous group-#{d}")

        # do kazdeho predpripraveneho bloku nalijeme vizualizaci
        for group, group_data of _data

            # vybereme kontejner pro vizualizaci
            content = @SVG.chart.selectAll("g.group-#{group}").data(group_data)
            obj = _.first(_.values(group_data))
            if _.isUndefined(obj)
                obj =
                    colors:
                        color1: 0
                        color2: 0
                        color3: 0
            color = @options.colors[group] or "color-#{obj.color1}-#{obj.color2}"

            # NOTE: tohle je specialitka srovnavaciho grafu v detailu elektriny
            # az pri vykresleni vim, kterym streamum se venuji (tj. v dusledku s jakou
            # barvou se v grafu operuje). V tento okamzik muzu zmenit CSS tridu na Xove
            # ose podle zvoleneho ID.
            @SVG.axis.select("g.x.axis.group-#{group}")
                .attr 'class', (d) ->
                    value = @className.baseVal
                    if value.indexOf('color-') == -1
                        value = "#{value} #{color}"
                    else
                        value = value.replace(/color-\d-\d/, color)
                    value

            _x_scale = @get_x_scale_variant(group)
            line = d3.svg.line()
                .interpolate('linear')
                .x((d) => _x_scale(d[0]))
                .y((d) =>
                    if not(d[1]?)
                        return @scales.y(0)
                    if _.isObject(d[1]) and _.has(d[1], 'sum_value')
                        return @scales.y(d[1].sum_value)
                    else
                        return @scales.y(d[1])
                )


            # NOTE:
            # Ve volani handle_continuous_outages posilam do parametru "border" vyraz
            # group=='previous'. Pokud je true, tak handle_continuous_outages doplni prvni a posledni
            # vzorek v datech na stejnem timestampu ale s hodnotou undefined (coz zpusobi pri
            # vykresleni stahnuti krivky na 0 a plocha se vykresli korektne). U carovych
            # prubehu to neni nutne, takze se tam posila false (vypada to i lepe, krivka
            # nikam nepada).
            # Je to ale prilis konkretni kod. Pokud bych nahodou porovnaval vice nez dva prubehy
            # (pojmenovane 'actual' a 'previous') budu to tu muset predelat.
            shape_data = _.filter(group_data, (d) -> d.data.length > 0)

            shapes = content.selectAll("path").data(shape_data)
            shapes.exit().remove()
            shapes
                .attr("class", color)
                .attr('clip-path', (d) => "url(#clip-#{@unique_prefix}-#{group}-#{d.root_id})")
                .transition(CHART_SETTINGS.transition)
                .attr('d', (d) => line(@handle_continuous_outages(d.data, group=='previous')))
            shapes.enter()
                .append('path')
                .attr("class", color)
                .attr('clip-path', (d) => "url(#clip-#{@unique_prefix}-#{group}-#{d.root_id})")
                .transition(CHART_SETTINGS.transition)
                .attr('d', (d) => line(@handle_continuous_outages(d.data, group=='previous')))

    shape_selector: (id, unselect=false) ->
        @SVG.chart.selectAll("g.block").filter ->
            if unselect
                true
            else
                value = @className.baseVal
                value.indexOf("group-#{id}") == -1
