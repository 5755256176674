class BaseDetailChart extends BaseChart

    get_x_scale_variant: (group) ->
        @scales.x[group]

    get_margins: ->
        margins = super()
        if @data.actual.type not in ['months', 'day']
            margins.bottom = margins.bottom + @INTERNALS.square
        margins
