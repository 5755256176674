# Schodovy a sloupcovy graf.
# Srovnavaci varianta, posledni stream z kolekce dat je interpretovan jako sloupcovy
# graf, vsechny pred nim jako schodove.
#
class StepComparisonChart extends BaseComparisonChart

    get_margins: ->
        margins = super()
        margins.bottom = margins.bottom + @INTERNALS.square
        margins

    get_bubble_data: () ->
        group = 'actual'
        format = @options.format
        scale = @get_x_scale_variant(group)
        step = (@data[group].filter.step or 24) * 3600
        day_mode = @data[group].filter.step == undefined
        start = @options.range[group].start
        delta = @get_x_scale_variant('actual').domain()[0] - @get_x_scale_variant('previous').domain()[0]
        get_ids = => @show

        # datova struktura pro rychlejsi nalezeni streamu
        streams_for_bubble =
            actual: _.object(_.map(@reject_level_streams(@data.actual.data), (v) -> [v.root_id, v]))
            previous: _.object(_.map(@reject_level_streams(@data.previous.data), (v) -> [v.root_id, v]))

        out =
            width: scale(start+step) - scale(start)
            group: group
            scale: scale
            step: step
            start: start
            empty: => false
            aligned: (timestamp) ->
                start + Math.floor((timestamp - start) / step) * step
            bubble: (timestamp) =>
                out = []
                ids = get_ids()

                stream = streams_for_bubble.actual[ids[0]]
                if _.isEmpty(stream) or _.isUndefined(stream)
                    stream =
                        data: [[null,null]]
                        unit: null
                value = _.find(stream.data, (d) -> d[0] == timestamp)

                # Value wasn't found and we know we're hopping over days, let's try to fiddle
                # the timestamp to see if we'll be lucky this time
                if not(value?) and day_mode
                    # Try adding 1 hour to the aligned timestamp
                    value = _.find(stream.data, (d) -> d[0] == (timestamp+3600))

                if not(value?) and day_mode
                    # Try taking 1 hour from the aligned timestamp
                    value = _.find(stream.data, (d) -> d[0] == (timestamp-3600))

                if @options.colors? and _.has(@options.colors, 'actual')
                    color_actual = @options.colors.actual

                out.push
                    label: format.bubble_label_actual
                    value: if value? then get_chart_value(value[1]) else null
                    unit: stream.unit
                    class: if color_actual? then color_actual else "color-#{stream.color1 or 0}-#{stream.color2 or 0}"
                    type: 'value'

                # hodnota pro predchozi obdobi (nalezeni nejblizsiho vzorku)
                # Bacha! Musi se brat v potaz vypadky, at se jako nejblizsi hodnota nenajde
                # treba neco co bylo platne pred 12 hodinama...
                _delta = timestamp - scale.domain()[0]
                pscale = @get_x_scale_variant('previous')
                timestamp = pscale.domain()[0] + _delta

                stream = streams_for_bubble.previous[ids[0]]
                if _.isEmpty(stream) or _.isUndefined(stream)
                    stream =
                        data: [[null,null]]
                        unit: null
                value = _.find(stream.data, (d) -> d[0] == timestamp)

                # Value wasn't found and we know we're hopping over days, let's try to fiddle
                # the timestamp to see if we'll be lucky this time
                if not(value?) and day_mode
                    # Try adding 1 hour to the aligned timestamp
                    value = _.find(stream.data, (d) -> d[0] == (timestamp+3600))

                if not(value?) and day_mode
                    # Try taking 1 hour from the aligned timestamp
                    value = _.find(stream.data, (d) -> d[0] == (timestamp-3600))

                if @options.colors? and _.has(@options.colors, 'previous')
                    color_previous = @options.colors.previous

                out.push
                    label: format.bubble_label_previous
                    value: if value? then get_chart_value(value[1]) else null
                    unit: stream.unit
                    class: if color_previous? then color_previous else "color-#{stream.color1 or 0}-#{stream.color2 or 0}"
                    type: 'value'

                out

        if @data.actual.type == 'months'
            #* Date format for charts ticks where space is usually limited.
            #* Uses special format explained at http://momentjs.com/docs/#/displaying/format/.
            #* Feel free to leave a comment should you have any doubts.
            out.label = [((d) -> d.format(format.day_label))
                         ((d) -> moment(d).subtract(delta, 'seconds').format(format.day_label))]
        else
            # pohled nad tydennimi daty
            out.label = [((d) -> d.format(format.day_label))
                         ((d) -> moment(d).subtract(delta, 'seconds').format(format.day_label))
                         ((d) -> "#{d.format(format.time_label)}-#{moment(d).add(step, 'seconds').format(format.time_label)}")]
        out

    #
    # vykresleni spinacich schemat
    draw_background: ->
        main_group = _.keys(@data)[0]
        if @data[main_group].switching != undefined
            @draw_regions(@data[main_group].switching)

    draw_shapes: (ids) ->
        # vyfiltrujeme jen zvoleny stream
        _data =
            actual: @reject_level_streams(@filter_root_ids(@data.actual.data, ids))
            previous: @reject_level_streams(@filter_root_ids(@data.previous.data, ids))

        # vytahnem jmena skupin
        groups = _.keys(_data)
        groups.reverse() # posledni ze sady dat bude vykreslen jako sloupce; pri renderu ho ale musime vykreslit jako prvniho

        # vytahneme jmeno skupiny, ktera bude reprezentovana jako sloupce
        col_group = _.first(groups)

        # bloky
        # NOTE: tady neni treba mit update block, protoze grupy se v case nemeni
        # (meni se jen jejich obsah, takze opravdu jen jednorazove vykresleni)
        blocks = @SVG.chart.selectAll("g.block").data(groups)
        blocks.enter()
            .append('g')
            .attr("class", (d) =>
                if d == col_group
                    "block bar group-#{d}"
                else
                    "block step group-#{d}"
            )

        # Taaaak.....
        # V tento moment mame v SVG vykreslene grupy (kontejnery pro vlastni vizualizaci).
        # Ty se uz dal v case nijak menit nebudou.
        # Ted je ale treba nalit do kontejneru nejaky obsah.

        # vypocet optimalni sirky jednoho sloupecku
        _x_scale = @get_x_scale_variant(col_group)
        domain = _x_scale.domain()
        one_column = @get_one_column_info(domain[0], domain[1], @data[col_group].type, @data[col_group].filter.step)

        # do kazdeho predpripraveneho bloku nalijeme vizualizaci
        for group of _data
            # vybereme kontejner pro vizualizaci
            content = @SVG.chart.selectAll("g.block.group-#{group}").data(_data[group])
            # zjistime barvu (ve srovanvacim grafu ma vsecko stejnou barvu,
            # na urovni CSS je ale rozliseno predchozi/aktualni obdobi a s nim
            # i pruhlednost)
            obj = _.first(_.values(_data[group]))
            if _.isUndefined(obj)
                obj =
                    colors:
                        color1: 0
                        color2: 0
                        color3: 0
            color = @options.colors[group] or "color-#{obj.color1}-#{obj.color2}"
            color_hatch = if @options.colors[group] then group else "#{obj.color1}_#{obj.color2}"

            # NOTE: tohle je specialitka srovnavaciho grafu v detailu elektriny
            # az pri vykresleni vim, kterym streamum se venuji (tj. v dusledku s jakou
            # barvou se v grafu operuje). V tento okamzik muzu zmenit CSS tridu na Xove
            # ose podle zvoleneho ID.
            @SVG.axis.select("g.x.axis.group-#{group}")
                .attr 'class', (d) ->
                    value = @className.baseVal
                    if value.indexOf('color-') == -1
                        value = "#{value} #{color}"
                    else
                        value = value.replace(/color-\d-\d/, color)
                    value

            if group == col_group
                @_draw_bars(content, group, color, color_hatch, one_column.width, one_column.delta, ids[0], @data[group].filter.type)
            else
                @_draw_steps(content, group, color, one_column.width, ids[0], @data[group].filter.type)

    _draw_bars: (content, group, color, color_hatch, width, delta, root_id, period_type) ->
        _x_scale = @get_x_scale_variant(group)

        rects = content.selectAll('rect').data((d) ->
            # u kazdeho sloupce pridame info, jestli jsme pro nej detekovali vypadek

            # TODO: prepsat prehledneji
            _.map(d.data, (t) ->
                outage = _.filter(d.outages, (o) ->
                    c1 = t[0] + delta <= o[0]
                    c2 = t[0] >= o[1]
                    not (c1 or c2))
                opacity = _.reduce(outage, (memo, o) ->
                    if t[0] <= o[0]
                        start = o[0]
                    else
                        start = t[0]
                    if t[0]+delta <= o[1]
                        end = t[0]+delta
                    else
                        end = o[1]
                    memo + end - start
                , 0)
                opacity = opacity / delta
                has_outage = outage > 0
                timestamp = t[0]

                bar = [timestamp, get_chart_value(t[1]), has_outage, 0.1 + (0.3 - opacity*.3)]
                return bar
            )
        )


        # odebrani nepotrebnych sloupecku
        rects.exit()
            .transition(CHART_SETTINGS.transition)
            .attr("y", (d) =>
                @sizes.chart.height # stahnuti na 0 pozici (v grafu je Y osa obracena)
            )
            .attr("height", 0) # stahnuti na 0 vysku
        # aktualizace sloupecku ktere zustaly v grafu
        rects
            .attr("class", (d) -> "#{d[2] and 'outage' or ''} #{color}")
            .transition(CHART_SETTINGS.transition)
            .attr("x", (d) ->
                _x_scale(d[0])
            )
            .attr("y", (d) =>
                @scales.y(get_chart_value(d[1]))
            )
            .attr("height", (d) => @sizes.chart.height - @scales.y(get_chart_value(d[1])))   # zmena vysky sloupce (musi se zmenit Y i vyska)
            .style("fill", (d) -> d[2] and "url(#hatch_#{color_hatch})" or '')
        # pridani novych sloupecku do grafu
        rects.enter()
            .append("rect")
            .attr("class", (d) -> "#{d[2] and 'outage' or ''} #{color}")
            .transition(CHART_SETTINGS.transition)
            .attr("x", (d) ->
                _x_scale(d[0])
            )
            .attr("y", (d) =>
                @scales.y(get_chart_value(d[1]))
            )
            .attr("height", (d) => @sizes.chart.height - @scales.y(get_chart_value(d[1])))
            .attr("width", width)
            .style("fill", (d) -> d[2] and "url(#hatch_#{color_hatch})" or '')

    _draw_steps: (content, group, color, width, root_id, period_type) ->
        _x_scale = @get_x_scale_variant(group)

        line = d3.svg.line()
            .interpolate('step-after')
            .x((d) => _x_scale(d[0]))
            .y((d) =>
                if d[1]?
                    return @scales.y(get_chart_value(d[1]))
                else
                    return @scales.y(0)
            )

        shapes = content.selectAll("path").data((d) -> [d.data])

        # "odebrani" schodu
        # ve skutecnosti nejde o odebrani, ale stahnuti krivky na hodnotu 0
        shapes.exit()
            .transition(CHART_SETTINGS.transition)
            .attr 'd', (d) =>
                line(@void_continuos_data(@options.range[group], 30)) # TODO: jaky pocet?
        # aktualizace schodu -> musime mu vnutit novy tvar
        shapes
            .attr("class", (d) -> color)
            .attr('mask', (d) => "url(#mask-#{@unique_prefix}-#{group}-#{root_id})")
            .transition(CHART_SETTINGS.transition)
            .attr 'd', (d) =>
                out = line(@handle_step_outages(d, @options.range[group]))
                @draw_last_step(out, width)
        # pridani novych schodu
        shapes.enter()
            .append('path')
            .attr("class", (d) -> color)
            .attr('mask', (d) => "url(#mask-#{@unique_prefix}-#{group}-#{root_id})")
            .transition(CHART_SETTINGS.transition)
            .attr 'd', (d) =>
                out = line(@handle_step_outages(d, @options.range[group]))
                @draw_last_step(out, width)

    # TODO:
    make_outages_masks: ->
        group = 'actual'
        scale = @get_x_scale_variant(group)
        domain = scale.domain()
        one_column = @get_one_column_info(domain[0], domain[1], @data[group].type, @data[group].filter.step)
        offset = domain[0] % one_column.delta

        for key, stream_data of @data[group].data
            # pokud stream nema klic outages, tak to balime
            if not _.has(stream_data, 'outages') or stream_data.outages.length == 0
                continue
            if is_level_stream(stream_data.id)
                continue

            # element popisujici masku; jeho ID je dulezite!
            mask = @SVG.defs.append('mask')
                        .attr('id', "mask-#{@unique_prefix}-#{group}-#{stream_data.root_id}")

            dash = []
            visible = []
            previous_timestamp = undefined
            previous_was_in_outage = undefined
            start = domain[0]

            # pro vsechny timestampy v rozmezi domain[0] a domain[1] vzdalene od sebe navzajem one_column.delta
            for timestamp in [domain[0]...domain[1]] by one_column.delta
                # vyfiltruje seznam vypadku (pocat. a konc. timestamp vypadku), do kterych spada aktualni timestamp v cele sve sirce delta
                outages = _.filter(stream_data.outages, (outage) ->
                    outage_start = outage[0]
                    outage_end = outage[1]

                    # je cela sirka aktualniho sloupce pred pocatkem vypadku?
                    hits_start = timestamp + one_column.delta <= outage_start
                    # je pocatek aktualniho sloupce za hranici konce vypadku?
                    goes_beyond_end = timestamp >= outage_end

                    # pokud je jedna z predchozich podminek splnena, zahod timestamp
                    # pokud ani jedna z predchozich podminek neplati, znamena to, ze cely sloupec je uvnitr obdobi vypadku a projde filtrem
                    not (hits_start or goes_beyond_end))

                # je aktualni timestamp uvnitr nejakeho vypadku
                is_current_in_outage = outages.length > 0

                # pokud se nevi, jestli byl predchozi sloupec ve vypadku
                if previous_was_in_outage is undefined
                    # a aktualni sloupec je, pridejme jeho pocatek a konec do pole k vysrafovani
                    if is_current_in_outage
                        dash.push([timestamp, timestamp+one_column.delta])

                # pokud byl predchozi sloupec ve vypadku
                else if previous_was_in_outage
                    # a aktualni neni
                    if not is_current_in_outage
                        # posune se start na zacatek aktualniho sloupce
                        start = timestamp
                    # zjistime si posledni prvek pole k vysrafovani
                    last_dash = _.last(dash)
                    last_dash_start = last_dash[0]
                    last_dash_end = last_dash[1]

                    # pokud je konec posledniho srafovaneho sloupce stejny s pocatkem predchoziho sloupce (a ten byl jak vime ve vypadku)
                    if last_dash_end == previous_timestamp
                        # jenom posuneme konec posledniho srafovaneho sloupce na konec predchoziho sloupce
                        dash[dash.length-1][1] = previous_timestamp+one_column.delta
                    # pokud neni konec posledniho srafovaneho sloupce stejny s pocatkem predchoziho sloupce
                    # a zaroven pocatek posledniho srafovaneho sloupce nesedi na pocatek prechoziho sloupce
                    # nebo konec posledniho srafovaneho sloupce nesedi na konec prechoziho sloupce
                    else if last_dash_start != previous_timestamp or last_dash_end != previous_timestamp+one_column.delta
                        # pridame do pole ke sraovani cely predchozi sloupec
                        dash.push([previous_timestamp, previous_timestamp+one_column.delta])

                # pokud predchozi sloupec nebyl ve vypadku
                else
                    # a aktualni je
                    if is_current_in_outage
                        # pridame do pole viditelnych od zarazky start po konec predchoziho sloupce
                        visible.push([start, previous_timestamp+one_column.delta])
                        # a do pole k vysrafovani aktualni sloupec v cele delce
                        dash.push([timestamp, timestamp+one_column.delta])

                # nastavime priznak a pocatecni timestamp aktualniho sloupce pro pristi iteraci
                previous_was_in_outage = is_current_in_outage
                previous_timestamp = timestamp


            # osetreni posledniho pruchodu
            # pokud predchozi sloupec nebyl ve vypadku
            if not previous_was_in_outage
                # pridame do pole viditelnych usek od zarazky po konec domeny
                visible.push([start, domain[1]])

            # prevedeni na souradnice
            margin = 3
            visible = _.map(visible, (d) -> [scale(d[0])-margin, scale(d[1])+margin])
            dash = _.map(dash, (d) -> [scale(d[0])+margin, scale(d[1])-margin])

            for r in visible
                mask.append('rect')
                    .attr('x', r[0])
                    .attr('y', 0)
                    .attr('width', r[1]-r[0])
                    .attr('height', @sizes.chart.height)
                    .attr('fill', '#fff')

            for r in dash
                mask.append('rect')
                    .attr('x', r[0])
                    .attr('y', 0)
                    .attr('width', r[1]-r[0])
                    .attr('height', @sizes.chart.height)
                    .attr('fill', "url(#hatch_stroke_outage)")

            for r in stream_data.poweroffs
                mask.append('rect')
                    .attr('x', scale(r[0])-margin)
                    .attr('y', 0)
                    .attr('width', scale(r[1])-scale(r[0])+margin*2)
                    .attr('height', @sizes.chart.height)
                    .attr('fill', "#000")

    shape_selector: (id, unselect=false) ->
        @SVG.chart.selectAll("g.block").filter ->
            if unselect
                true
            else
                value = @className.baseVal
                value.indexOf("group-#{id}") == -1
