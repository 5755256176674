class DonutChart extends BaseChart
    @total_key = undefined
    @agg_keys = undefined
    @plot_data = undefined
    @all_keys = undefined

    set_layers: ->
        # hlavni kontejner s SVG elementem
        out =
            root:
                d3.select(@options.container).append("svg")
                    .attr("class", @SVG_CLASS)
                    .attr("width", @options.width)
                    .attr("height", @options.height)
                    .attr("viewBox", "0 0 #{@options.width} #{@options.height}")

        # vyplivneme jednotlive vrstvy
        for layer in @LAYERS
            out[layer.id] = out.root.append("g").attr('class', "#{layer.id}-layer")
            if layer.transform
                out[layer.id].attr("transform", "translate(#{@sizes.margins.left}, #{@sizes.margins.top})")

        out

    get_sizes: ->
        out =
            width: @options.width
            height: @options.height
            margins: @get_margins()         # marginy uvnitr, oblast mezi samotnou plochou grafu a vnejsimi hranicemi
        out.chart = @get_chart_size(out)    # plocha grafu
        out

    get_margins: ->
        out =
            left: @options.width / 2
            top: @options.height / 2
            right: 0
            bottom: 0

    get_id_base: (stream_id)->
        stream_id.split("_")[0]

    has_sub_streams: (streams,stream_id)->
        if stream_id?
            root_id = @get_id_base(stream_id)
            for key in streams
                if key.indexOf(root_id) > -1
                    return true
            return false
        else
            return false


    get_total_key: (keys)->
        _key = undefined
        max = Number.NEGATIVE_INFINITY
        for key in keys
            if @data[key].sum > max
                max = @data[key].sum
                _key = key
        if max == Number.NEGATIVE_INFINITY
            undefined
        else
            _key

    get_agg_keys: (keys)->
        _.reject(keys, (key)-> key.indexOf("_all") == -1)

    get_streams_data: (keys)->
        out = []
        for key in keys
            out.push @data[key]
        out

    # TODO: odstranit, jakmile prejdeme na underscore verze 1.6.0 a vyssi
    _.partition = (array, predicate)->
        pass = []
        fail = []
        _.each array, (elem)->
            if predicate(elem)
                pass.push(elem)
            else
                fail.push(elem)
        [pass, fail]

    preprocess_data: (data) ->
        pre =
            total:
                id: null
                sum: 0
                color: 'color-none'
            outerData: []
            innerAgg: []
            innerData: []

        @data = data.toJSON()
        @all_keys = _.map(@data,(i)-> i.root_id)
        @data = _.indexBy(@data,"root_id")
        @agg_keys = @get_agg_keys(@all_keys)

        @total_key = @get_total_key(@agg_keys)

        if @total_key?
            pre.total = @data[@total_key]

            # vytahnu z id "hlavniho" streamu cast pred podtrzitkem
            id_base = @get_id_base(@total_key)

            # pole vsech klicu rozseknu na ty, co zacinaji na id_base a zbytek
            parts = _.partition(@all_keys, (k)-> k.indexOf(id_base) == 0)
            # z obou casti odstranim vsechny agregacni klice
            parts[0] = _.difference(parts[0],@agg_keys)
            parts[1] = _.difference(parts[1],@agg_keys)

            # pokud zustaly v prvni casti pole jine klice nez klic "hlavniho" streamu
            if @has_sub_streams(parts[0],@total_key)
                # nahazu je do outer struktury pro graf
                pre.outerData = @get_streams_data(_.filter(parts[0],(k)-> k.indexOf(id_base) == 0))

            # a zbyle klice v druhe casti do inner struktury
            pre.innerData = @get_streams_data(_.filter(parts[1],(k)-> k.indexOf(id_base) == -1))
            pre.innerAgg = @get_streams_data(_.difference(@agg_keys,@total_key))

            @plot_data =  _.indexBy(_.flatten(pre),"root_id")
        else
            # Pokud ma zakaznik jen jeden stream
            if _.size(@data) == 1
                lonely_stream_id = _.keys(@data)
                # tak ho dame automaticky do vnejsiho kruhu
                pre.outerData = @get_streams_data(lonely_stream_id)
                @plot_data =  _.indexBy(pre.outerData,"root_id")
                # a bude zaroven predstavovat celkovou sumu spotreby
                pre.total.sum = @plot_data[lonely_stream_id].sum
            # else
                # TODO: zakaznik ma vice streamu, ale zadny neni hlavni..

        # spocitam soucet sum pres streamy v inner casti
        innerSum = if pre.innerData? and not _.isEmpty(pre.innerData) then _.reduce(pre.innerData, (memo,d) ->
                d.sum + memo
            , 0)
        # a to same pro ty v outer casti
        outerSum = if pre.outerData? and not _.isEmpty(pre.outerData) then _.reduce(pre.outerData, (memo,d) ->
                d.sum + memo
            , 0)
        # a to same pro ty v outer casti
        innerAggSum = if pre.innerAgg? and not _.isEmpty(pre.innerAgg) then _.reduce(pre.innerAgg, (memo,d) ->
                d.sum + memo
            , 0)

        # pokud je v kterekoliv casti soucet sum mensi nez celek, vyplnim jeji zbytek vatou
        if innerSum? and innerSum < pre.total.sum
            pre.innerData.push
                id: null
                sum: pre.total.sum - innerSum
                color: 'color-none'
        if outerSum? and outerSum < pre.total.sum
            pre.outerData.push
                id: null
                sum: pre.total.sum - outerSum
                color: 'color-none'
        if innerAggSum? and innerAggSum < pre.total.sum
            pre.innerAgg.push
                id: null
                sum: pre.total.sum - innerAggSum
                color: 'color-none'

        # vyplivnu data pro graf
        pre

    analyze_data: ->
        true

    draw_shapes: (show=undefined) ->
        thickness = @options.width / 20
        font =  @options.width / 8 # v pixelech
        radius = Math.min(@options.width, @options.height) / 2

        pie = d3.layout.pie()
            .value((d)-> d.sum)
            .sort(null)

        if _.isNaN(_.first(pie([@data.total])).endAngle)
            # Z dat se nedaji zjistit uhly pro vysece, radsi to vzdam, nez posilat do D3ky NaN hodnoty
            return true

        outerArc = d3.svg.arc()
            .innerRadius(radius - thickness)
            .outerRadius(radius)
        innerArc = d3.svg.arc()
            .innerRadius(radius - (5 * thickness))
            .outerRadius((radius - thickness))
        ringArc = d3.svg.arc()
            .innerRadius((radius - thickness))
            .outerRadius((radius - thickness))

        @SVG.chart.selectAll(".outerAgg")
            .data(pie([@data.total]))
            .enter()
            .append("path")
            .attr("class", (d)->
                if d.data.root_id in show
                    "outerAgg #{d.data.color}"
                else
                    "outerAgg #{d.data.color} inactive"
            )
            .attr("d", outerArc)
            .attr("id", (d)->
                d.data.root_id
            )

        @SVG.chart.selectAll(".innerAgg")
            .data(pie(@data.innerAgg))
            .enter()
            .append("path")
            .attr("class", (d)->
                if d.data.root_id in show
                    "innerAgg #{d.data.color}"
                else
                    "innerAgg #{d.data.color} inactive"
            )
            .attr("d", innerArc)
            .attr("id", (d)->
                d.data.root_id
            )

        @SVG.chart.selectAll(".outer")
            .data(pie(@data.outerData))
            .enter()
            .append("path")
            .attr("class", (d)->
                if d.data.root_id in show
                    "outer #{d.data.color}"
                else
                    "outer #{d.data.color} inactive"
            )
            .attr("d", outerArc)
            .attr("id", (d)->
                d.data.root_id
            )

        @SVG.chart.selectAll(".ring")
            .data(pie([{sum: 1}]))
            .enter()
            .append("path")
            .attr("class", "ring")
            .attr("stroke", "rgba(255,255,255,.05)")
            .attr("d", ringArc)

        @SVG.chart.selectAll("inner")
            .data(pie(@data.innerData))
            .enter()
            .append("path")
            .attr("class", (d)->
                if d.data.root_id in show
                    "inner #{d.data.color}"
                else
                    "inner #{d.data.color} inactive"
            )
            .attr("d", innerArc)
            .attr("id", (d)->
                d.data.root_id
            )

        @SVG.overlay.selectAll("#percentage")
            .data([{value:1}])
            .enter().append("text")
            .text((d)-> "")
            # .attr("fill", "#fff")
            .attr("x", @options.width / 2)
            .attr("y", @options.height / 2 + (font / 3))
            .style("text-anchor", "middle")
            .style("font-size", font + "px")
            .attr("id","percentage")

    draw: (show=undefined) =>

        # v parametru show dostaneme seznam streamu, ktere se maji v grafu objevit
        # osetrime si jeho podobu tak, at ma podobu [id1, id2, ...]
        if _.isString(show)
            show = [show]
        else if _.isUndefined(show)
            show = _.map @data, (d, i) -> d.root_id
        @show = show

        # vykresleni grafu
        if _.isUndefined(@SVG)
            # ve strance jeste nic, poskladame cely aparat
            @SVG = @set_layers()
        @draw_shapes(show)

    percentage: (root_id, hide=false) =>
        if hide or not root_id? or not @plot_data?
            @SVG.overlay.selectAll("#percentage")
                .text("")
        else
            percentage = (100 * @plot_data[root_id].sum / @data.total.sum).toPrecision(3)
            percentageString = percentage + "%"
            if percentage < 0.1
                percentageString = "< 0.1%"
            @SVG.overlay.selectAll("#percentage")
                .text(percentageString)

    select_shapes_except: (id, unselect=false) ->
        @SVG.chart.selectAll("g.chart-layer path").filter ->
            if unselect
                true
            else
                @id.indexOf("#{id}") == -1

    select_all_shapes: ->
        @SVG.chart.selectAll("g.chart-layer path")

    highlight: (id) ->
        @select_shapes_except(id).classed('dim', (d) -> true)

    unhighlight: ->
        @select_all_shapes().classed('dim', (d) -> false)

    # vsem castem kolace krome ids se nastavi trida .inactive
    # vola se pri zaskrtavani streamu nad detailnim grafem
    toggle: (ids)->
        @select_all_shapes().classed('inactive', (d)->
            if d.data.root_id in ids
                false
            else
                true
        )
