# "Tachometr" pro vyjadreni pomeru.
#
# Samotne vykresleni realizuje metoda `draw(value)`, kde `value`
# je float cislo na skale @VALUE_RANGE.
class RatioChart

    DESATURATE_RATIO: 1    # timhle cislem se deli procento saturace tachace v oblasti za indikatorem
    LIGHTEN_RATIO: 1.7       # timhle cislem se nasobi svetlost tachace v oblasti za indikatorem
    OPACITY_RATIO: '.25'   # jakou opacitu ma mit cast tachace za indikatorem

    GREEN: "hsl(120,100%,40%)"    # barvy do tachace
    YELLOW: "hsl(60,100%,40%)"
    RED: "hsl(0,100%,40%)"

    RANGE: 120                  # budik je reprezentovan 120° vyseci
    STEPS: 1                    # jemnost barevneho prechodu (nejjemnejsi)
    INDICATOR: 0.01             # tloustka predelu mezi normalni a desaturovanou casti
    VALUE_RANGE: [0, 2]         # z venku prichazi hodnoty v tomto rozsahu, 0=-100%, 2=+100%

    constructor: (@size, @selector, @format) ->

        @radius = @size / 2

        # mapovani vstupniho rozsahu na interni 0..1
        @value_scale = d3.scale.linear().domain(@VALUE_RANGE).range([0, 1])

        # pomocna funkce, ktera vrati tvar vysece (ze kterych je seskladan cely gradient)
        @arc = d3.svg.arc()
            .innerRadius(@radius - @radius / 5)
            .outerRadius(@radius)
            .startAngle((d) -> d.startAngle)
            .endAngle((d) -> d.endAngle)

        # definice gradientu pro tachac
        @color = d3.scale.linear()
            .domain([0, @RANGE / 2, @RANGE])
            .range([@RED, @YELLOW, @GREEN])

    # Vypocita pomocna data pro vykresleni budiku s barevnym prechodem
    #
    gauge_colors: (value) ->
        internal_value = @value_scale(value)
        pi = Math.PI
        indicator_drawed = false

        d3.range(@RANGE / @STEPS).map (d, i) =>
            i *= @STEPS
            c = d3.hsl(@color(i))
            opacity = 1
            boundary = @RANGE * (1 - internal_value)
            delta = boundary * @INDICATOR
            if i > boundary - delta and i < boundary + delta and not indicator_drawed
                indicator_drawed = true
            else if i < boundary
                c = d3.hsl(c.h, c.s / @DESATURATE_RATIO, c.l * @LIGHTEN_RATIO)
                opacity = @OPACITY_RATIO
            out =
                startAngle: i * (pi / 180)
                endAngle: (i + 2) * (pi / 180)
                fill: c.toString()
                fill_opacity: opacity

    # Vykresli budik s cislem. Vstupni hodnota `value` musi byt v rozsahu @VALUE_RANGE.
    #
    draw: (value) ->
        @data = @gauge_colors(value)
        rel_value = value - 1

        # tutaj budeme kreslit
        svg = d3.select(@selector)
            .append("svg")
            .attr("width", "100%")
            .attr("height", "100%")
            .attr("viewBox", "0 0 #{@size} #{@size / 2}") # TODO: zajisti plynulou zmenu meritka pri zmene width/height

        if _.isNaN(value)
            svg.attr("opacity", .2)

        # budik
        svg.append("g")
            .attr("transform", "translate(#{@radius},#{@radius+1}) rotate(#{@RANGE/2}) scale(-1,1)")
            .selectAll('path')
            .data(@data)
            .enter()
            .append('path')
            .attr("d", (x) => @arc(x))
            # .attr("stroke-width", 1)
            # .attr("stroke", (d) -> d.fill)
            # .attr("stroke-opacity", (d) -> d.fill_opacity)
            .attr("fill", (d) -> d.fill)
            .attr("fill-opacity", (d) -> d.fill_opacity)

        # cislo pod budikem
        svg.append("text")
            .style('text-anchor', 'middle')
            .style('font-weight', 800)
            .style 'font-size', () ->
                size = if value > 10.9 then 30 else 40
                "#{size}px"
            .attr('x', @size / 2)
            .attr('y', @size / 3)
            .attr 'fill', () =>
                # barva cisla odpovida pozici v budiku
                if _.isNaN(value)
                    "#abadae"
                else
                    v = 1 - @value_scale(value)
                    @color(v*120).toString()
            .text () ->
                # generovani cisla pod budik
                if value > 100.9
                    "+∞ %"
                else if rel_value < 0
                    out = Math.round(rel_value*100)
                    if _.isNaN(out)
                        @format
                    else
                        "#{out}%"
                else
                    v = Math.round(rel_value*100)
                    if _.isNaN(v)
                        @format
                    else
                        if v == 0 then "#{v}%" else "+#{v}%"
