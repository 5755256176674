class BaseChart

    # TODO:
    DEBUG: false

    # CSS trida na hlavnim SVG prvku
    SVG_CLASS: 'chart'

    # Vrstvy
    SVG: undefined
    LAYERS: [
        {id: 'grid_background', clipping: true, transform: true}
        {id: 'background', clipping: true, transform: true}
        {id: 'grid', clipping: true, transform: true}
        {id: 'highlight', clipping: true, transform: true}
        {id: 'chart', clipping: true, transform: true}
        {id: 'axis', clipping: false, transform: true}
        {id: 'overlay', clipping: false, transform: false}
        {id: 'events', clipping: true, transform: true}
    ]

    # Interni coordinate system
    # NOTE: vychazi z experimentalniho ladeni
    #
    INTERNALS:
        width: 1140
        height: 310
        square: 12

    # TODO:
    EXTREME_MODIFIER: 5 # v procentech

    # TODO:
    Y_TICKS: 5

    # Pokud zobrazene obdobi trva alespon WEEK_GRID_TRESHOLD dni, tak na pozadi
    # zobrazime "grid" zvyraznnujici tydny (pod kazdym tydnem se nakresli tmavy/svetly
    # obdelnik).
    WEEK_GRID_TRESHOLD: 4

    # TODO:
    # - popsat co je mozne nastavovat v options
    # - popsat strukturu v data
    #
    constructor: (options={}) ->
        defaults =
            data: {}
            width: undefined # TODO: nejaka automatika podle dodaneho elementu?
            height: undefined
            range: {}
            type: undefined
            justification: 5
            colors: {}
            format: undefined
            group: undefined
            first_wday: 1
            feed_timezone: undefined
            format_si_prefix: undefined
        @options = _.extend defaults, options
        @options.comparison = @options.range.length > 1
        @tz = @options.feed_timezone
        @unique_prefix = parseInt(Math.random() * 10000000)
        @data = @preprocess_data(@options.data)
        @scales = undefined
        @axis = undefined
        @formater = new ChartFormater(@options.type, @options.format_si_prefix)

        # nejprve musime zanalyzovat co vlastne budeme kreslit
        # povaha dat ma vliv na podobu grafu
        @analysis = @analyze_data()

        # spocteme si rozmery grafu, okraju, apod.
        # (ted uz tusime s cim budeme pracovat)
        @sizes = @get_sizes()

    filter_root_ids: (group_data, ids, property='root_id')->
        return @filter_property(group_data, ids, property)

    filter_stream_ids: (group_data, ids, property='id')->
        return @filter_property(group_data, ids, property)

    filter_property: (group_data, ids, property='id')->
        filtered_streams = _.chain(group_data)
        .filter (stream)->
            return stream[property] in ids
        .value()

        return filtered_streams

    reject_empty_streams: (group_data)->
        filtered_streams = _.chain(group_data)
        .filter (stream)->
            return stream.data.length > 0
        .value()

        return filtered_streams

    reject_level_streams: (group_data)->
        filtered_streams = _.chain(group_data)
        .filter (stream)->
            return not(is_level_stream(stream.id))
        .value()

        return filtered_streams

    # TODO:
    preprocess_data: (data) ->
        if @options.group == undefined
            for group of data
                if not _.has(data[group].filter, 'step') or data[group].filter.step == undefined
                    return data
        else if @options.group == false
            return data

        out = {}
        for group of data
            out[group] =
                type: data[group].type
                filter: _.clone(data[group].filter)
                data: []
                switching: data[group].switching
            for key, item of data[group].data
                _obj = _.clone(item)
                _obj.data = @groupify(_obj.data, data[group].filter.step, data[group].filter.group_type)
                out[group].data.push(_obj)

        out

    # TODO:
    # - asi by to melo rikat i jaky casovy rozsah se ma kreslit ne?
    # - jakoze seznam show je co se ma vykreslit za cary, z toho vyplyva meritko Y
    # - potrebuju jeste ale signal co za X to ma byt
    #
    # KDE KONCIM
    # - asi bych mel nejak zacit kreslit ten samotny prubeh ne?
    # - ale asi ne v bazove tride vlastne...
    draw: (show=undefined) ->

        # v parametru show dostaneme seznam streamu, ktere se maji v grafu objevit
        # osetrime si jeho podobu tak, at ma podobu [id1, id2, ...]
        if _.isString(show)
            show = [show]
        else if _.isUndefined(show)
            show = _.map @data, (d, i) -> d.root_id
        @show = show

        # vykresleni grafu
        if _.isUndefined(@SVG)
            # ve strance jeste nic, poskladame cely aparat
            @SVG = @set_layers()
            @draw_passive_parts()
            @scales = @get_scales(show)
            @axis = @get_axis()
            @grid = @get_grid()
            @make_outages_masks()
            @setup_event_layer()

        else
            # ve strance uz graf je
            # musime ho tedy vyprudit, neprekreslovat, jen donutit ke zmene

            # zmena osy Y
            extreme = @_get_y_scale_extreme(show)
            @scales.y.domain(extreme)
            @axis.y.svg_left.transition(CHART_SETTINGS.transition).call(@axis.y.axis_left)
            @axis.y.svg_right.transition(CHART_SETTINGS.transition).call(@axis.y.axis_right)
            @grid.y.svg_left.transition(CHART_SETTINGS.transition).call(@grid.y.axis_left)
            @grid.y.svg_right.transition(CHART_SETTINGS.transition).call(@grid.y.axis_right)

            # posunuti osy X (pokud se v datech objevi zaporne cislo, osa X putuje)
            zero = @sizes.chart.height
            for idx in [0...@axis.x.axis.length]
                @axis.x.axis[idx].tickPadding(@sizes.chart.height - zero + 5 + @INTERNALS.square*idx)
                @axis.x.svg[idx].transition(CHART_SETTINGS.transition).attr("transform", "translate(0, #{zero})").call(@axis.x.axis[idx])

        @draw_grid_background()
        @draw_background()
        @draw_shapes(show)


    # TODO:
    # - u spojitych prubehu se musi kreslit cara
    #   - tenhle kod muze zustat uplne stejny, akorat ze ten obdelnik udelam
    #     uzounky a musim ho vystredit na danou pozici (kua mozna vlastne
    #     staci sirka 1 ne?)
    #   - zmeni se ale to zarovnavani na nasobky delty
    #   - cim tenci tim musi byt opacity vetsi
    #
    # - KUA dyt ja uz tu vlastne resim i tu bublinku ne?
    #   - na Xovou osu by to chtelo vypisovat presny cas
    #   - do cele neclipnute vrstvy
    #   - idealne na nejakem obedlnicku, ktery bude mit nastavenou polopruhlednost
    #     do ztracena na levo/pravo
    #   - do nej vypisu cely popisek
    #       - ten se bude lisit podle obdobi ktere se zobrazuje
    #       - treba:
    #           pondeli 3.4.2012 14:15
    #           14:38:56
    #
    #
    # TODO:
    # - tohle je kandidat na samostatnou tridu
    # - vyzkouset jak slozite je pridat zobrazovani bublinky
    #   - bublina by se mela ukazovat jen kdyz jsou na dane pozici data
    #   - bublina musi byt dynamicky pozicovana
    setup_event_layer: ->
        bubble = new Bubble
            tz: @tz
            square: @INTERNALS.square
            data: @data
            SVG: @SVG
            sizes: @sizes
            range: @options.range
            unique_prefix: @unique_prefix
            scales: @scales
            helper: @get_bubble_data()
            formater: @formater

    # Vykresli na pozadi obdelniky, ktere zvyraznuji tydny.
    #
    # TODO:
    # - zobecnit a pouzit i pro vykresleni tarifu
    # - mohl by brat obecny seznam [od-do, od-do, ...]
    # - akorat nevim jak by to bylo s temi classami (mozna [od-do-class, od-do-class, od-do-class, ...])
    # - make_week_grid by se pak zmenila pouze na vygenerovani datove struktury
    # - vykreslovatko by bylo bokem
    #
    draw_background: ->
        regions = @get_week_regions()
        if regions != undefined and regions.length > 0
            @draw_regions(regions)

    draw_grid_background: () ->

        bck = @SVG.grid_background.selectAll('rect').data([1])
        bck
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', @sizes.chart.width)
            .attr('height', @sizes.chart.height)
            .attr('class', 'grid-background')
        bck.enter()
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', @sizes.chart.width)
            .attr('height', @sizes.chart.height)
            .attr('class', 'grid-background')

    # TODO:
    #
    draw_regions: (regions, default_class=undefined) ->
        group = _.first(_.keys(@options.range))
        scale = @get_x_scale_variant(group)

        bck = @SVG.background.selectAll('rect').data(regions)
        bck
            .attr('x', (d) -> scale(d[0]))
            .attr('y', 0)
            .attr('width', (d) -> scale(d[1]) - scale(d[0]))
            .attr('height', @sizes.chart.height)
            .attr('class', (d) -> d[2] or default_class or '')
        bck.enter()
            .append('rect')
            .attr('x', (d) -> scale(d[0]))
            .attr('y', 0)
            .attr('width', (d) ->
                w = scale(d[1]) - scale(d[0])
                if w < 0
                    return 0
                return w
            )
            .attr('height', @sizes.chart.height)
            .attr('class', (d) -> d[2] or default_class or '')

        bck = @SVG.grid_background.selectAll('rect').data(['dummy'])
        bck
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', @sizes.chart.width)
            .attr('height', @sizes.chart.height)
            .attr('class', 'grid-background')
        bck.enter()
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', @sizes.chart.width)
            .attr('height', @sizes.chart.height)
            .attr('class', 'grid-background')

    # TODO:
    #
    get_week_regions: ->
        group = _.first(_.keys(@options.range))
        range = @options.range[group]
        start = moment.unix(range.start).tz(@tz)
        end = moment.unix(range.end).tz(@tz)
        days = moment(end).add('seconds', 1).diff(start,'days')

        if days < @WEEK_GRID_TRESHOLD
            # zobrazujem prilis malo dni v grafu, grid v pozadi by jenom matl
            return undefined

        wday = start.day()
        if wday == @options.first_wday
            date = moment(start).unix()
        else if wday < @options.first_wday
            date = moment(start).add('days', @options.first_wday - wday).unix()
        else
            date = moment(start).add('days', 7 - (wday - @options.first_wday)).unix()
        variant = 0
        last_date = range.start

        regions = []
        while date < range.end
            regions.push([last_date, date, "week-#{variant % 2}"])
            last_date = date
            variant += 1
            date += 7 * 24*3600

        regions.push([last_date, range.end, "week-#{variant % 2}"])
        regions

    # TODO:
    # - musi se implementovat v odvozenych tridach
    draw_shapes: (ids) ->
        throw
            name: 'typeError'
            message: 'not implemented'

    # Analyzuje vstupni data z @data a vraci objekt s pomocnymi udaji (treba maximum
    # v Y), na zaklade kterych se generuji treba scales, apod.
    #
    # TODO:
    # - popsat strukturu
    # analyze_data: ->
    #     out =
    #         outages: @_analyze_outages()
    #     _.extend out, @_analyze_x_data()
    #     _.extend out, @_analyze_y_data()
    #     out
    analyze_data: ->
        out = {}
        _.extend out, @_analyze_x_data()
        _.extend out, @_analyze_y_data()
        out

    # TODO:
    # Vytvori pod root SVG prvkem sadu tagu <clipPath>, ktere se pak uvadi s pomoci
    # url(ID) reference v krivkach vykreslenych na strance. Timto propojenim dosahneme
    # maskovani vypadku v krivkach (skryvame oblast s vypadkem, kde muze byt krivka
    # bordelozne vykreslena).
    #
    make_outages_masks: ->
        visible_rect = (clipping, group, root_id, x1, x2) =>
            clipping.append('rect')
                .attr('x', x1)
                .attr('y', 0)
                .attr('width', x2-x1)
                .attr('height', @sizes.chart.height)

        for group of @data
            scale = @get_x_scale_variant(group)

            for key, stream of @data[group].data
                if not _.has(stream, 'outages') or stream.outages.length == 0
                    continue

                # element popisujici masku; jeho ID je dulezite!
                clipping = @SVG.defs.append('clipPath')
                            .attr('id', "clip-#{@unique_prefix}-#{group}-#{stream.root_id}")

                # masku musime udelat inverzne; tj. definovat oblasti ktere maji byt videt
                # (outages popisuji to co nema byt videt)
                idx = 0
                x1 = scale(scale.domain()[0])
                while idx < stream.outages.length
                    interval = stream.outages[idx]
                    x2 = scale(interval[0])
                    if x2-x1 <= 0
                        idx = idx + 1
                        continue
                    visible_rect(clipping, group, stream.root_id, x1, x2)
                    x1 = scale(interval[1])
                    idx = idx + 1

                x2 = scale(scale.domain()[1])
                if x2-x1 > 0
                    visible_rect(clipping, group, stream.root_id, x1, x2)

    _analyze_x_data: ->
        out = {x_min: {}, x_max: {}}
        for group of @data
            out.x_min[group] = @options.range[group].start
            out.x_max[group] = @options.range[group].end
        out

    _analyze_y_data: ->
        out = {y_min: {}, y_max: {}, y_unit: []}
        for group of @data # muze byt actual nebo previous
            if _.isUndefined(@data[group].data) or _.isEmpty(@data[group].data)
                console.warn "undefined or empty data"
                # vratime si aspon jednotku
                out.y_unit = _.first(_.sortBy(_.pairs(_.countBy(out.y_unit)), (d) -> d[1]))[0]
                return out
            out.y_unit = out.y_unit.concat(_.map(@data[group].data, (d) -> d.unit))
            out.y_min[group] = {}
            out.y_max[group] = {}

            for key, stream of @data[group].data
                if is_level_stream(stream.id)
                    continue

                if stream.data.length > 1
                    out.y_min[group][stream.root_id] = @find_extreme_value([stream], _.min, @options.range[group].start, @options.range[group].end)
                    out.y_max[group][stream.root_id] = @find_extreme_value([stream], _.max, @options.range[group].start, @options.range[group].end)
                    if out.y_min[group][stream.root_id] > 0
                        out.y_min[group][stream.root_id] = 0
                else
                    extreme = @find_extreme_value([stream], _.min, @options.range[group].start, @options.range[group].end)
                    if extreme < 0
                        out.y_min[group][stream.root_id] = extreme
                        out.y_max[group][stream.root_id] = 0
                    else
                        out.y_min[group][stream.root_id] = 0
                        out.y_max[group][stream.root_id] = extreme

                # mezery kolem dat je mozne pocitat az pote, co zname oba extremy
                delta = Math.abs(out.y_max[group][stream.root_id] - out.y_min[group][stream.root_id])
                space = delta / 100 * @EXTREME_MODIFIER
                if out.y_min[group][stream.root_id] < 0
                    # mezeru pod minimalni hodnotou udelame jen tehdy, pokud je mensi nez 0
                    # (tj. je pod X osou). pro 0 a vetsi hodnoty je prirozenejsi, pokud je
                    # 0 presne na ose X
                    out.y_min[group][stream.root_id] = out.y_min[group][stream.root_id] - space

                out.y_max[group][stream.root_id] = out.y_max[group][stream.root_id] + space

                if not _.isFinite(out.y_max[group][stream.root_id])
                    out.y_max[group][stream.root_id] = out.y_min[group][stream.root_id]

            # pokud zobrazujem jen kladna cisla, tak zaciname dycinky na 0
            if _.all(out.y_min[group], (d) -> d >= 0)
                out.y_min[group] = _.object(_.map(_.keys(out.y_min[group]), (d) -> [d, 0]))


        # NOTE: naivita, ale mela by pro typicke pripady zafungovat
        # pro kazdy ze streamu se vytahne jednotka, ty se pak shluknou k sobe
        # podle poctu a ta co se vyskytuje nejcasteji vyhrala
        out.y_unit =  _.first(_.sortBy(_.pairs(_.countBy(out.y_unit)), (d) -> d[1]))[0]

        out


    # --- Rozmery

    # Zakladni rozmery pro vykresleni grafu.
    #
    # Interne se rozmery odvozuji takto:
    #
    #                      width
    #   +--------------------------------------------------+
    #   |                top margin                        |
    #   | left     +----------------------------+    right |
    #   | margin   | chart.width x chart.height |   margin |  height
    #   |          +----------------------------+          |
    #   |                bottom margin                     |
    #   +--------------------------------------------------+
    #
    # Bacha! Zamerne pisu **interne**, zakladni rozmer width x height je odvozen z INTERNALS,
    # coz je vnitrni souradnicovy system.
    # Pak zde mame jeste dalsi rozmery co prijdou z vnejsku (v options) a podle nich se
    # nastavi vnejsi rozmer grafu (takze pak fyzicky bude mit zadane rozmery). Vevnitr
    # v grafu se ale jede podle INTERNALS.
    #
    get_sizes: ->
        out =
            width: @INTERNALS.width
            height: @INTERNALS.height
            margins: @get_margins()         # marginy uvnitr, oblast mezi samotnou plochou grafu a vnejsimi hranicemi
        out.chart = @get_chart_size(out)    # plocha grafu
        out

    # Definuje okraje kolem grafu.
    #
    get_margins: ->
        out =
            left: @INTERNALS.square * 6
            top: @INTERNALS.square
            right: @INTERNALS.square * 6
            bottom: @INTERNALS.square * 2

    # Rozmery plochy, do ktere se kresli prubeh.
    #
    get_chart_size: (sizes) ->
        out =
            width: sizes.width - sizes.margins.left - sizes.margins.right
            height: sizes.height - sizes.margins.top - sizes.margins.bottom

    # --- Meritka

    get_scales: (ids) ->
        out =
            x: @get_x_scales()
            y: @get_y_scale(ids)

    get_x_scales: ->
        out = {}
        for group of @data
            out[group] = d3.scale.linear()
                 .domain([@analysis.x_min[group], @analysis.x_max[group]])
                 .range([0, @sizes.chart.width])
        out

    get_y_scale: (ids) ->
        d3.scale.linear()
            .domain(@_get_y_scale_extreme(ids))
            .range([@sizes.chart.height, 0]) # TODO: proc tu byla 1?

    _get_y_scale_extreme: (ids) ->
        min = []
        max = []
        for group of @data
            for key, stream of @data[group].data
                if not(stream.root_id in ids)
                    continue
                if is_level_stream(stream.id)
                    continue
                min.push(@analysis.y_min[group][stream.root_id])
                max.push(@analysis.y_max[group][stream.root_id])

        if _.all(min, (d) -> d >= 0)
            min = [0]

        [_.min(min), _.max(max)]

    # --- Osy

    get_axis: ->
        out =
            x: @get_x_axises()
            y: @get_y_axis()

    # TODO: popisky

    get_x_axises: ->
        out = {axis: [], svg: []}
        tz = @tz

        keys = _.keys(@data).sort()
        idx = 0

        for group in keys
            _scale = @get_x_scale_variant(group)
            td = new TickDivider(_scale)
            fmt = td.get_format(@options.type)

            axis = d3.svg.axis()
                .scale(_scale)
                .tickValues(td.make_tick_values()) # TODO:
                .tickFormat (d) ->
                    m = moment.unix(d).tz(tz)
                    _m = m.format('H:mm:ss')
                    if _m == '0:00:00'
                        m.format(fmt.day)
                    else if _m == '23:59:59'
                        m.add(2, 'minutes').startOf('day').format(fmt.day)
                    else
                        m.format(fmt.hour)
                .tickPadding(5 + @INTERNALS.square*idx)
                .orient("bottom")
            out.axis.push(axis)
            out.svg.push(@SVG.axis.append("g")
                .attr("transform", "translate(0, #{@sizes.chart.height})")
                .attr("class", "x axis group-#{group}")
                .call(axis))

            # pridani CSS class na popisky
            @SVG.axis.selectAll("g.x.axis.group-#{group} text")
                .attr 'class', (d) ->
                    content = d3.select(@).text()
                    if content.indexOf(':') == -1
                        'day'
                    else
                        'hour'
            idx += 1
        out

    get_y_axis: ->
        axis_left = d3.svg.axis()
            .scale(@scales.y)
            .ticks(@Y_TICKS)
            .tickFormat((d) =>
                @formater.format(d, @analysis.y_unit, @scales.y.domain()[1])
            )
            .orient("left")
        axis_right = d3.svg.axis()
            .scale(@scales.y)
            .ticks(@Y_TICKS)
            .tickFormat((d) =>
                @formater.format(d, @analysis.y_unit, @scales.y.domain()[1])
            )
            .orient("right")
        out =
            axis_left: axis_left
            axis_right: axis_right
            svg_left: @SVG.axis.append("g")
                    .attr("class", "y axis left")
                    .call(axis_left)
            svg_right: @SVG.axis.append("g")
                    .attr("class", "y axis right")
                    .attr("transform", "translate(#{@sizes.chart.width}, 0)")
                    .call(axis_right)
        out

    get_grid: ->
        out =
            x: @get_x_grid()
            y: @get_y_grid()

    get_x_grid: ->
        _axis = _.first(@axis.x.axis)
        axis = d3.svg.axis()
            .scale(_axis.scale())
            .tickValues(_axis.tickValues())
            .tickSize(-@sizes.chart.height)
            .tickFormat('')
        out =
            axis: axis
            svg: @SVG.grid.append("g")
                .attr("transform", "translate(0, #{@sizes.chart.height})")
                .attr("class", "x axis")
                .call(axis)

    get_y_grid: ->
        _axis_left = @axis.y.axis_left
        _axis_right = @axis.y.axis_right

        axis_left = d3.svg.axis()
            .scale(_axis_left.scale())
            .ticks(@Y_TICKS)
            .tickSize(-@sizes.chart.width)
            .tickFormat('')
            .orient("left")
        axis_right = d3.svg.axis()
            .scale(_axis_right.scale())
            .ticks(@Y_TICKS)
            .tickSize(-@sizes.chart.width)
            .tickFormat('')
            .orient("right")
        out =
            axis_left: axis_left
            axis_right: axis_right
            svg_left: @SVG.grid.append("g")
                .attr("class", "y axis left")
                .call(axis_left)
            svg_right: @SVG.grid.append("g")
                .attr("class", "y axis right")
                .attr("transform", "translate(#{@sizes.chart.width}, 0)")
                .call(axis_right)


    # --- Vrstvy

    # Nastavi zakladni vrsty, do kterych se pak kresli samotny graf.
    #
    set_layers: ->
        # hlavni kontejner s SVG elementem
        out =
            root:
                d3.select(@options.container).append("div")
                    .attr("class", "svg-wrap")
                    .attr("style", "padding-bottom: #{(@INTERNALS.height / @INTERNALS.width) * 100}%;")
                    .append("svg")
                    .attr("class", @SVG_CLASS)
                    .attr("width", "100%")
                    .attr("height", "100%")
                    .attr("viewBox", "0 0 #{@INTERNALS.width} #{@INTERNALS.height}") # TODO: zajisti plynulou zmenu meritka pri zmene width/height

        # TODO:
        if @DEBUG
            out.root.append('rect').attr('fill', '#fff').style('opacity', .15).attr('width', @sizes.width).attr('height', @sizes.height)

        # maska na chart
        @CLIPPING_ID = "chart-clipping-#{@unique_prefix}"
        out.defs = out.root.append('defs')
        out.defs.append('clipPath')
            .attr('id', @CLIPPING_ID)
            .append('rect')
            .attr('x', 0)
            .attr('y', 0)
            .attr('width', @sizes.chart.width)
            .attr('height', @sizes.chart.height)

        # vyplivneme jednotlive vrstvy
        for layer in @LAYERS
            out[layer.id] = out.root.append("g").attr('class', "#{layer.id}-layer")
            if layer.transform
                out[layer.id].attr("transform", "translate(#{@sizes.margins.left}, #{@sizes.margins.top})")
            if layer.clipping
                out[layer.id].attr('clip-path', "url(##{@CLIPPING_ID})")

        # TODO
        if @DEBUG
            out.chart.append('rect').attr('fill', '#fff').style('opacity', .15).attr('width', @sizes.chart.width).attr('height', @sizes.chart.height)

        out


    # --- Helpery

    # Obchcavka D3, na kterou jsem pysny! :)
    # Schodove grafy kreslime s pomoci d3.line.interpolate('step-after'). Tohle
    # se chova pekne, tak jak chceme, ale s jednou vyjimkou -- posledni hodnota
    # v grafu se vykresli blbe. Linka spadne/vzroste na spravnou Y pozici, ale
    # nevykresli se uz posledni horizontalni carka.
    #
    draw_last_step: (d, width=undefined) ->
        if not _.isNull(d)
            split = d.split('H')
            # NOTE: bacha! pokud split nenajde H, nesmime pokracovat, jinak se D3 poblije!
            # a asi by mel mit aspon 3 prvky, ne?
            if split.length > 2
                # vytahnem si 2 posledni horizontalni linky z grafu
                last = _.map(_.last(d.split('H'), 2), (d) -> parseInt(d.split('V')[0]))
                # zjistime si z nich deltu
                dx = last[1] - last[0]
                # pridame k puvodnimu atributu "d" prilepek H<x-pos>
                "#{d}H#{parseInt(last[1])+dx}"
            else if width and d.indexOf('M') == 0
                return "#{d}h#{width}"
            else
                console.info "Can't d.split('H')"
                return d
        else
            return d


    # TODO:
    #
    find_extreme: (data, extreme_fn, filter_fn, getter_fn) ->
        # nejdriv najdeme extrem v kazdem ze vstupnich streamu
        extreme_per_item = []
        for key, stream of data
            _data = _.map(_.filter(stream.data, filter_fn), getter_fn)
            extreme_per_item.push(extreme_fn(_data))

        # nakonec vratime extrem ze vsech streamu
        extreme_fn(extreme_per_item)

    # TODO: nezapocitavaji se undefined prvky
    find_extreme_value: (data, extreme_fn, start_ts, end_ts) ->
        @find_extreme(data, extreme_fn, (d) ->
            (d[1] != undefined) and (d[0] >= start_ts and d[0] <= end_ts)
        , (d) ->
            get_chart_value d[1]
        )

    # TODO: berem vse
    find_extreme_timestamp: (data, extreme_fn) ->
        @find_extreme(data, extreme_fn, (d) ->
            true
        , (d) -> d[0])

    # TODO:
    draw_passive_parts: ->
        # horni rantl grafu
        @SVG.axis.append('line')
            .attr('x1', 0)
            .attr('x2', @sizes.chart.width)
            .attr('y1', 0)
            .attr('y2', 0)
            .attr('class', 'axis passive')

        # dolni rantl grafu (vetsinou bude prekryty osou, ale kdyby se zmenila Y skala
        # i do zapornych hodnot, musi se tam objevit)
        @SVG.axis.append('line')
            .attr('x1', 0)
            .attr('x2', @sizes.chart.width)
            .attr('y1', @sizes.chart.height)
            .attr('y2', @sizes.chart.height)
            .attr('class', 'axis passive')

        # pravy rantl grafu
        @SVG.axis.append('line')
            .attr('x1', @sizes.chart.width)
            .attr('x2', @sizes.chart.width)
            .attr('y1', 0)
            .attr('y2', @sizes.chart.height)
            .attr('class', 'axis passive')

        # srafura; TODO: mozna spis resit pres CSS ne?
        for color1 in [0..4]
            for color2 in [0..4]
                @SVG.defs
                    .append('pattern')
                    .attr('id', "hatch_#{color1}_#{color2}")
                    .attr('class', 'hatch')
                    .attr('patternUnits', 'userSpaceOnUse')
                    .attr('width', 4)
                    .attr('height', 4)
                    .append('path')
                    .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
        @SVG.defs
            .append('pattern')
            .attr('id', "hatch_actual")
            .attr('class', 'hatch')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
        @SVG.defs
            .append('pattern')
            .attr('id', "hatch_previous")
            .attr('class', 'hatch')
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')

        @SVG.defs
            .append('pattern')
            .attr('id', "hatch_stroke_outage")
            .attr('patternUnits', 'userSpaceOnUse')
            .attr('width', 4)
            .attr('height', 4)
            .append('path')
            .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
            .style('stroke', '#fff') # NOTE: pres CSS se mi to nedari nastavit, proto je to zde natvrdo
            .style('stroke-width', 1.4)

    # TODO:
    # - mozna neni treba resit, staci nasadit masky?
    handle_continuous_outages: (data, borders=false) ->
        if data.length == 0
            return data

        out = []
        last_item = _.last(data)
        for idx in [data.length-1..0]
            item = data[idx]
            if item[1] == undefined
                out.push([item[0], last_item[1]])
            else
                out.push(item)
            last_item = item

        if borders
            last = _.last(out)
            out.push([last[0], undefined])

            first = _.first(out)
            out.unshift([first[0], undefined])

        out

    # TODO:
    # - mozna neni treba resit, musim se podivat do lib.coffee jak jsou data ze serveru zpracovany
    handle_step_outages: (data, range) ->
        # TODO: musim tu udelat zpatky ty hrabe
        # (cybejici klice na undefined)
        data

    void_continuos_data: (range, count=600, value=undefined) ->
        out = [[range.start, value]]
        delta = Math.round((range.end - range.start) / count)
        for idx in [0...count-1]
            out.push([range.start + idx * delta, value])
        out.push([range.end, value])
        out

    # TODO:
    # -
    get_one_column_info: (start, end, type, hour_fraction) ->
        duration_seconds = end - start
        out =
            delta: undefined
            width: undefined

        if type == 'months'
            # zobrazujeme mesicni data, co sloupec to den
            day_seconds = 3600 * 24
            delta = Math.round(duration_seconds / day_seconds)
            out.delta = day_seconds
            out.width = (@sizes.chart.width / delta) * .95
        else
            hour_fraction_seconds = 3600 * hour_fraction
            delta = Math.round(duration_seconds / hour_fraction_seconds)
            out.delta = hour_fraction_seconds
            out.width = (@sizes.chart.width / delta) * .95

        return out

    # TODO:
    #
    # BACHA JAK SVINA!
    # - naivni implementace!
    # - offset se bere z prvniho prvku v datech
    # - muze se ale obecne stat, ze set dat bude pro dlouhe obdobi, ve kterem
    #   nekolikrat dojde ke zmene casoveho pasma
    # - lepsi by bylo offset vyhodnocovat prubezne nad kazdym prvkem, nebo
    #   jeste lepe udelat si dopredu nejakou mapu pro obdobi data[0] a data[-1]
    #   a teprve s touto pak v iteraci nejak nakladat
    #
    groupify: (data, step, type='sum') ->
        if not data or _.isEmpty(data)
            return []

        offset = moment.unix(parseInt(data[0][0])).tz(@tz).zone() * 60

        # zgrupujem
        groups = _.groupBy data, (i) =>
            t = parseInt(i[0]) + offset
            t - t % (3600 * step)

        # kazda grupa bude prevedena na [timestamp, suma]
        item = []
        for k of groups
            val = _.reduce groups[k], (memo, v) ->
                memo + (v[1] != undefined and v[1] or 0)
            , 0
            if type == 'avg'
                val = val / groups[k].length

            item.push([parseInt(k) - offset, val])

        item

    # TODO:
    #
    highlight: (id) ->
        @shape_selector(id).classed('dim', (d) -> true)

    unhighlight: ->
        @shape_selector(undefined, true).classed('dim', (d) -> false)

    # TODO:
    #
    merge_timestamps: (ids, groups=undefined) ->
        out = []
        for group of @data
            if groups != undefined and group not in groups
                continue
            for key, stream of @data[group].data
                if not(stream.root_id in ids)
                    continue
                timestamps = _.map(stream.data, (d) -> d[0])
                out = _.union(out, timestamps)
        _.sortBy out, (d) -> d
