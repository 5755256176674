# Sloupcovy graf.
# Detailni varianta, ktera dokaze zobrazit vice sloupcu vedle sebe
# (grouped bar).
#
class StepBarChart extends BaseDetailChart

    get_bubble_data: () ->
        group = 'actual'
        format = @options.format
        scale = @get_x_scale_variant(group)
        step = (@data[group].filter.step or 24) * 3600
        day_mode = @data[group].filter.step == undefined
        start = @options.range[group].start
        get_ids = => @show

        out =
            width: scale(start+step) - scale(start)
            group: group
            scale: scale
            step: step
            start: start
            empty: => false
            aligned: (timestamp) ->
                start + Math.floor((timestamp - start) / step) * step
            bubble: (timestamp) =>
                out = []
                ids = get_ids()
                for group of @data
                    for key, stream of @data[group].data
                        if stream.root_id not in ids
                            continue

                        value = _.find(stream.data, (d) -> d[0] == timestamp)

                        # Value wasn't found and we know we're hopping over days, let's try to fiddle
                        # the timestamp to see if we'll be lucky this time
                        if not(value?) and day_mode
                            # Try adding 1 hour to the aligned timestamp
                            value = _.find(stream.data, (d) -> d[0] == (timestamp+3600))

                        if not(value?) and day_mode
                            # Try taking 1 hour from the aligned timestamp
                            value = _.find(stream.data, (d) -> d[0] == (timestamp-3600))

                        if value?
                            value = get_chart_value(value[1])

                        if not(is_level_stream(stream.id))
                            out.push
                                label: stream.label
                                value: value
                                unit: stream.unit
                                class: "color-#{stream.color1 or 0}-#{stream.color2 or 0}"
                                type: 'value'
                out

        if @data.actual.type == 'months'
            out.label = [((d) -> d.format(format.day_label))]
        else if @data.actual.type == 'day'
            out.label = [((d) -> "#{d.format(format.time_label)}-#{moment(d).add(step, 'seconds').format(format.time_label)}")]
        else
            out.label = [((d) -> d.format(format.day_label))
                         ((d) -> "#{d.format(format.time_label)}-#{moment(d).add(step, 'seconds').format(format.time_label)}")]
        out

    #
    # vykresleni spinacich schemat
    draw_background: ->
        main_group = _.keys(@data)[0]
        if @data[main_group].switching != undefined
            @draw_regions(@data[main_group].switching)

    draw_shapes: (ids) ->
        # vypocet optimalni sirky jednoho sloupecku
        group = _.first(_.keys(@data))
        start = @options.range[group].start
        end = @options.range[group].end
        one_column = @get_one_column_info(start, end, @data[group].type, @data[group].filter.step)

        _data =
            actual: @reject_level_streams(@filter_root_ids(@data[group].data, ids))

        if _data[group].length > 1
            column_width = one_column.width / _data[group].length
        else
            column_width = one_column.width

        for group of @data # iteruju pres klic
            if is_level_stream(@data[group].id)
                continue

            _x_scale = @get_x_scale_variant(group)

            # ids z parametru, ale serazena podle toho jak jsou v @data[group].data (diky tomu se mi budou kreslit sloupecky stale ve stejnem poradi)
            _ids = _.map _data[group], (stream) -> stream.root_id

            # Vynechame streamy s VT/NT a cokoliv, co nema zadna data
            shape_data = _.filter @data[group].data, (d) -> d.data.length > 0 and not(is_level_stream(d.id))

            # kontejnery pro jednotlive sloupce
            blocks = @SVG.chart.selectAll("g.block").data(shape_data)
            blocks
                .transition(CHART_SETTINGS.transition)
                .attr("transform", (d) -> "translate(#{_ids.indexOf(d.root_id)*column_width}, 0)")
            blocks.enter()
                .append('g')
                .attr("class", (d) =>
                    color = @options.colors[group] or "color-#{d.color1 or 0}-#{d.color2 or 0}"
                    "block bar group-#{group} shape-#{d.root_id} #{color}")
                .transition(CHART_SETTINGS.transition)
                .attr("transform", (d) -> "translate(#{_ids.indexOf(d.root_id)*column_width}, 0)")

            # sloupecky do kontejneru
            rects = blocks.selectAll('rect').data((d) ->
                if d.root_id in ids
                    _.map(d.data, (t) =>
                        outage = _.filter(d.outages, (o) ->
                            c1 = t[0] + one_column.delta <= o[0]
                            c2 = t[0] >= o[1]
                            not (c1 or c2)
                        )
                        opacity = _.reduce(outage, (memo, o) ->
                            if t[0] <= o[0]
                                start = o[0]
                            else
                                start = t[0]
                            if t[0]+one_column.delta < o[1]
                                end = t[0]+one_column.delta
                            else
                                end = o[1]
                            memo + end - start
                        , 0)
                        opacity = opacity / one_column.delta
                        [t[0], get_chart_value(t[1]), outage.length > 0, 1 - opacity*.7, "#{d.color1}_#{d.color2}", d.production or false])
                else
                    [])
            rects.exit()
                .transition(CHART_SETTINGS.transition)
                .attr("y", @sizes.chart.height)
                .attr("height", 0)
                .attr("width", 0)
            rects
                .transition(CHART_SETTINGS.transition)
                .attr("y", (d) => @scales.y(0))
                .attr("y", (d) =>
                    val = get_chart_value(d[1])
                    if d[5] == true and @options.pv_negative
                        @scales.y(0)
                    else
                        @scales.y(val)
                )
                .attr("height", (d) =>
                    val = get_chart_value(d[1])
                    if d[5] == true and @options.pv_negative
                        Math.abs((@sizes.chart.height - @scales.y(val)) - (@sizes.chart.height - @scales.y(0)))
                    else
                        (@sizes.chart.height - @scales.y(val)) - (@sizes.chart.height - @scales.y(0))
                )
                .attr("width", column_width)
                .attr("class", (d) -> d[2] and 'outage' or '')
                .style("fill", (d) -> d[2] and "url(#hatch_#{d[4]})" or '')
            rects.enter()
                .append("rect")
                .transition(CHART_SETTINGS.transition)
                .attr("x", (d) -> _x_scale(d[0]))
                .attr("y", (d) =>
                    val = get_chart_value(d[1])
                    if d[5] == true and @options.pv_negative
                        @scales.y(0)
                    else
                        @scales.y(val)
                )
                .attr("width", column_width)
                .attr("height", (d) =>
                    val = get_chart_value(d[1])
                    if d[5] == true and @options.pv_negative
                        Math.abs((@sizes.chart.height - @scales.y(val)) - (@sizes.chart.height - @scales.y(0)))
                    else
                        (@sizes.chart.height - @scales.y(val)) - (@sizes.chart.height - @scales.y(0))
                )
                .attr("class", (d) -> d[2] and 'outage' or '')
                .style("fill", (d) -> d[2] and "url(#hatch_#{d[4]})" or '')

    shape_selector: (id, unselect=false) ->
        @SVG.chart.selectAll("g.block").filter ->
            if unselect
                true
            else
                value = @className.baseVal
                value.indexOf("shape-#{id}") == -1
