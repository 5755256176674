# Pomocne funkce/tridy pro potreby grafu.
#

class TickDivider
    IDEAL_TICK_COUNT: 15 # NOTE: tohle by se mohlo odvozovat dynamicky od sirky grafu, ne?
    HOUR_STEPS: [2, 3, 4, 6, 8, 12, 24]

    constructor: (scale) ->
        domain = scale.domain()
        @start = domain[0]
        @end = domain[1]
        @dstart = moment.unix(@start)
        @dend = moment.unix(@end)
        @days = @dend.diff(@dstart, 'days')
        @hours = @dend.diff(@dstart, 'hours')
        @recipe = @get_recipe()

    get_recipe: ->
        if @hours <= 24
            hour_delta = 2
        else
            count = @IDEAL_TICK_COUNT - @days
            if count > 0
                hour_delta = @get_ideal_hour_step(@hours / count)
            else
                day_delta = Math.ceil(@days / @IDEAL_TICK_COUNT)
                hour_delta = day_delta * 24
        out =
            draw_hours: hour_delta > 0 and hour_delta <= 24
            hour_delta: hour_delta
            draw_days: @days >= 1 and @hours > 24

    get_ideal_hour_step: (number) ->
        deltas = _.map(@HOUR_STEPS, (d) -> [d, Math.abs(d-number)])
        mins = _.min(deltas, (d) -> d[1])
        mins[0]

    make_tick_values: ->
        point = @start
        out = []
        while point < @end
            out.push(point)
            point += @recipe.hour_delta * 3600

        if point != @end and out.length > 2
            delta = out[1] - out[0]

            last_delta = Math.abs(@end - _.last(out))
            if last_delta / delta > .7
                out.push(@end)
        out

    get_format: (switcher) ->
        out =
            day: FORMATS.chart_day_format
            hour: FORMATS.chart_hour_format

        if @recipe.draw_hours and not @recipe.draw_days
            # pokud se maji kreslit pouze hodiny, prevalime denni format hodinovym
            out.day = out.hour

        else if not @recipe.draw_hours and @recipe.draw_days
            # pokud se maji kreslit dny, prevalime hodinovy format dennim
            out.hour = out.day

        out



class ChartFormater

    constructor: (type, use_si_prefix) ->
        @options =
            switcher: type
            si_prefix: if _.isBoolean(use_si_prefix) then use_si_prefix else type != 'cost'

    format: (value, unit, cnf_numb=undefined) ->
        options = _.extend @options, {unit: unit}
        format_si(value, options, cnf_numb)

