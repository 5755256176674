# Graf se spojitym prubehem.
# Detailni varianta, ktera dokaze zobrazit vice prubehu (car) v jednom
# grafu.
#
class ContinuousDetailChart extends BaseDetailChart

    get_bubble_data: () ->
        timestamps = {}
        group = 'actual'
        scale = @get_x_scale_variant(group)
        get_ids = => @show
        out =
            width: 1
            group: group
            scale: scale
            empty: =>
                ids = get_ids()
                _temp = @merge_timestamps(ids)
                _temp.length < 1
            aligned: (timestamp) =>
                ids = get_ids()
                key = ids.join('-')
                if not _.has(timestamps, key)
                    timestamps[key] = @merge_timestamps(ids)
                _timestamps = timestamps[key]
                _timestamps_len = _.size(_timestamps)

                idx = _.sortedIndex(_timestamps, timestamp)
                if idx < 1
                    idx = 1
                else if idx > _timestamps_len
                    idx = _timestamps_len - 1
                _timestamps[idx]
            label: [(d) => d.format(@options.format)]
            bubble: (timestamp) =>
                out = []
                ids = get_ids()
                for group of @data
                    for key, stream of @data[group].data
                        if stream.root_id not in ids
                            continue
                        value = _.find(stream.data, (d) -> d[0] == timestamp)
                        if value?
                            value = if (_.isObject(value[1]) and _.has(value[1], 'sum_value')) then value[1].sum_value else value[1]
                        else
                            # hodnota neni v datech, tak ji budem interpolovat mezi predchozim a nasledujicim vzorkem
                            data_len = _.size(stream.data)
                            # TODO: delat map tady je nakladne, tahle cast se vola skoro pri kazdem posunu kurzorem!
                            idx = _.sortedIndex(_.map(stream.data,(d)->d[0]), timestamp)

                            if idx < 1
                                idx = 1
                            else if idx > data_len
                                idx = data_len - 1

                            startSample = stream.data[idx - 1]
                            endSample = stream.data[idx]

                            if _.has(@options, 'interpolate_strategy') and startSample?
                                switch @options.interpolate_strategy
                                    when 'interpolate'
                                        interpolate = d3.interpolateNumber(parseFloat(startSample[1]), parseFloat(endSample[1]))
                                        range = endSample[0] - startSample[0]
                                        value = interpolate((timestamp % range) / range)
                                    when 'keep-previous'
                                        value = startSample[1]
                                    when 'keep-next'
                                        value = endSample[1]
                                    else
                                        value = undefined
                            else
                                value = undefined

                        if not(is_level_stream(stream.id))
                            out.push
                                label: stream.label
                                value: value
                                unit: stream.unit
                                class: "color-#{stream.color1 or 0}-#{stream.color2 or 0}"
                                type: 'value'
                # TODO: u dvoutarifu zjistit ve kterem jsme pasmu
                # - a pak to doplnit jako dalsi radek do bublinky
                out

    #
    # TODO: vykresleni spinacich schemat
    # - jine grafy to nemaji
    # - na pozadi vukendy nebo spinanni; nikdy ne oboji
    draw_background: ->
        main_group = _.keys(@data)[0]
        if @data[main_group].switching != undefined
            @draw_regions(@data[main_group].switching)

    draw_shapes: (ids) ->
        for group of @data

            _x_scale = @get_x_scale_variant(group)

            line = d3.svg.line()
                .interpolate('linear')
                .x((d) => _x_scale(d[0]))
                .y((d) =>
                    if not(d[1]?)
                        return @scales.y(0)
                    if _.isObject(d[1]) and _.has(d[1], 'sum_value')
                        return @scales.y(d[1].sum_value)
                    else
                        return @scales.y(d[1])
                )

            shape_data = _.filter(@data[group].data, (d) -> d.data.length > 0)

            shapes = @SVG.chart.selectAll("path").data(shape_data)
            shapes.exit().style('stroke-opacity', 0)
            shapes
                .attr("class", (d) =>
                    color = @options.colors[group] or "color-#{d.color1 or 0}-#{d.color2 or 0}"
                    "line group-#{group} shape-#{d.root_id} #{color}")
                .style('stroke-opacity', (d) -> if d.root_id in ids then 1 else 0)
                .transition(CHART_SETTINGS.transition)
                .attr('clip-path', (d) => "url(#clip-#{@unique_prefix}-#{group}-#{d.root_id})")
                .attr 'd', (d) =>
                    if d.root_id in ids
                        line(@handle_continuous_outages(d.data))
                    else
                        line(@void_continuos_data(@options.range[group], d.data.length))
            shapes.enter()
                .append('path')
                .attr("class", (d) =>
                    color = @options.colors[group] or "color-#{d.color1 or 0}-#{d.color2 or 0}"
                    "line group-#{group} shape-#{d.root_id} #{color}")
                .attr('clip-path', (d) => "url(#clip-#{@unique_prefix}-#{group}-#{d.root_id})")
                .style('stroke-opacity', (d) -> if d.root_id in ids then 1 else 0)
                .attr 'd', (d) =>
                    if d.root_id in ids
                        line(@handle_continuous_outages(d.data))
                    else
                        line(@void_continuos_data(@options.range[group], d.data.length))

    shape_selector: (id, unselect=false) ->
        @SVG.chart.selectAll("path").filter ->
            if unselect
                true
            else
                value = @className.baseVal
                value.indexOf("shape-#{id}") == -1
