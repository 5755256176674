class BaseComparisonChart extends BaseChart

    get_x_scale_variant: (group) ->
        delta = @options.range[group].end + 1 - @options.range[group].start
        delta = delta / (3600 * 24)
        if delta < @options.justification
            @scales.x[group]
        else
            @scales.justified_x[group]

    get_margins: ->
        margins = super()
        margins.bottom = @INTERNALS.square + _.size(@data) * @INTERNALS.square
        margins

    get_scales: (ids) ->
        scales = super(ids)
        scales.justified_x = @get_justified_x_scales(scales.x)
        scales

    get_justified_x_scales: (scales) ->
        tz = @tz
        fwday = App.request 'Clocker:first-wday'

        # pocatecni den pro kazdou skupinu
        starts = _.map scales, (scale, group) ->
            [group, scale.domain()[0]]
        starts = _.object(starts)

        # den tydne (po, ut, ..) pro pocatecni den kazde ze skupin
        wdays = _.map starts, (start, group) ->
            out = moment(moment.unix(start).tz(tz).format())
            out = out.day()
            [group, if out == 0 then 7 else out]
        wdays = _.object(wdays)

        # o kolik dni je nutne start posunout, aby licoval na ostatni
        min_wday = _.min(_.values(wdays))
        deltas = _.map wdays, (wday, group) ->
            [group, wday - min_wday]
        deltas = _.object(deltas)

        # posunuti startu
        starts = _.map starts, (start, group) ->
            [group, start - deltas[group] * 3600*24]
        starts = _.object(starts)

        # koncovy den pro kazdou skupinu
        ends = _.map scales, (scale, group) ->
            [group, scale.domain()[1]]
        ends = _.object(ends)

        # delky jednotlivych obdobi
        range_lengths = _.map ends, (end, group) ->
            [group, end - starts[group]]
        range_lengths = _.object range_lengths

        # dopocty ke koncum jednotlivych dnu, aby vsechny koncily na stejnem dni tydne
        max_length = _.max(_.values(range_lengths))
        deltas = _.map range_lengths, (length, group) ->
            [group, max_length - length]
        deltas = _.object(deltas)

        # posunuti konce
        ends = _.map ends, (end, group) ->
            [group, end + deltas[group]]
        ends = _.object(ends)

        # vygenerovani finalnich skal pro kazdou grupu
        width = @sizes.chart.width
        out = _.map starts, (start, group) ->
            [group, d3.scale.linear().domain([start, ends[group]]).range([0, width])]
        _.object(out)
